import { safeSeidCiaRequest } from "../base";
export var getExportedPdf = safeSeidCiaRequest({
    method: "get",
    endpoint: "/carteira/pdf",
});
export var getNacionalidades = safeSeidCiaRequest({
    method: "get",
    endpoint: "/utils/nacionalidades",
});
export var getNaturalidadeByUf = function (uf) {
    return safeSeidCiaRequest({
        method: "get",
        endpoint: "/utils/cidades/uf/".concat(uf),
    });
};
export var getNaturalidadeById = function (id) {
    return safeSeidCiaRequest({
        method: "get",
        endpoint: "/utils/cidades/".concat(id),
    });
};
export var getSolicitationStatus = safeSeidCiaRequest({
    method: "get",
    endpoint: "/solicitacoes/status",
});
export var getTiposAnexos = safeSeidCiaRequest({
    method: "get",
    endpoint: "/utils/tipos-anexos",
});
export var getCIACard = safeSeidCiaRequest({
    method: "get",
    endpoint: "/carteira",
});
export var getPerson = safeSeidCiaRequest({
    method: "get",
    endpoint: "/solicitacoes/pessoa",
});
export var getSolicitation = safeSeidCiaRequest({
    method: "get",
    endpoint: "/solicitacoes/primeira-via",
});
export var getRenew = safeSeidCiaRequest({
    method: "get",
    endpoint: "/solicitacoes/renovacao",
});
export var createSolicitation = safeSeidCiaRequest({ method: "post", endpoint: "/solicitacoes/primeira-via" });
export var resendSolicitation = safeSeidCiaRequest({ method: "put", endpoint: "/solicitacoes/primeira-via/reenviar" });
export var createRenew = safeSeidCiaRequest({
    method: "post",
    endpoint: "/solicitacoes/renovacao",
});
export var resendRenew = safeSeidCiaRequest({
    method: "put",
    endpoint: "/solicitacoes/renovacao/reenviar",
});
export var getRegistrationUpdate = safeSeidCiaRequest({
    method: "get",
    endpoint: "/atualizacao-cadastral",
});
export var registrationUpdatePhoto = safeSeidCiaRequest({ method: "post", endpoint: "/atualizacao-cadastral/foto" });
export var resendregistrationUpdatePhoto = safeSeidCiaRequest({ method: "put", endpoint: "/atualizacao-cadastral/foto/reenviar" });
export var createAppWallet = safeSeidCiaRequest({
    method: "post",
    endpoint: "/carteira/wallet",
});
export var prepareCreateSolicitacaoPayload = function (payload) {
    var data = new FormData();
    var endereco = payload.endereco, pessoa = payload.pessoa;
    if (payload.id)
        data.append("id", payload.id);
    data.append("pessoa.nome", pessoa.nome.toUpperCase());
    data.append("pessoa.cpf", pessoa.cpf);
    data.append("pessoa.rg", pessoa.rg);
    data.append("pessoa.rgUf", pessoa.rgUf.toUpperCase());
    data.append("pessoa.rgDataExpedicao", "".concat(pessoa.rgDataExpedicao));
    data.append("pessoa.rgOrgaoExpedidor", pessoa.rgOrgaoExpedidor.toUpperCase());
    data.append("pessoa.sexo", pessoa.sexo.toUpperCase());
    data.append("pessoa.dataNascimento", "".concat(pessoa.dataNascimento));
    data.append("pessoa.nacionalidade", pessoa.nacionalidade.toUpperCase());
    data.append("pessoa.naturalidade", pessoa.naturalidade.toUpperCase());
    data.append("pessoa.mae", pessoa.mae.toUpperCase());
    if (pessoa.pai)
        data.append("pessoa.pai", pessoa.pai.toUpperCase());
    data.append("endereco.cep", endereco.cep);
    data.append("endereco.logradouro", endereco.logradouro.toUpperCase());
    data.append("endereco.numero", "".concat(endereco.numero));
    data.append("endereco.bairro", endereco.bairro.toUpperCase());
    data.append("endereco.complemento", endereco.complemento.toUpperCase());
    data.append("endereco.uf", endereco.uf.toUpperCase());
    data.append("endereco.cidade", endereco.cidade.toUpperCase());
    data.append("endereco.email", endereco.email.toLowerCase());
    data.append("endereco.telefone", endereco.telefone);
    if (payload.arquivos)
        payload.arquivos.forEach(function (arquivo, index) {
            data.append("arquivos[".concat(index, "].tipo"), "".concat(arquivo.tipo).toUpperCase());
            data.append("arquivos[".concat(index, "].file"), arquivo.file);
        });
    data.append("termoDeResponsabilidade", "".concat(payload.termoDeResponsabilidade));
    return data;
};
export var prepareResendSolicitacaoPayload = function (payload) {
    var data = new FormData();
    data.append("id", payload.id);
    if (payload.arquivos) {
        payload.arquivos.forEach(function (arquivo, index) {
            data.append("arquivos[".concat(index, "].tipo"), "".concat(arquivo.tipo).toUpperCase());
            data.append("arquivos[".concat(index, "].file"), arquivo.file);
        });
    }
    return data;
};
export var prepareCreateRenovacaoPayload = function (payload) {
    var data = new FormData();
    var endereco = payload.endereco;
    data.append("endereco.cep", endereco.cep);
    data.append("endereco.logradouro", endereco.logradouro.toUpperCase());
    data.append("endereco.numero", "".concat(endereco.numero));
    data.append("endereco.bairro", endereco.bairro.toUpperCase());
    data.append("endereco.complemento", endereco.complemento.toUpperCase());
    data.append("endereco.uf", endereco.uf.toUpperCase());
    data.append("endereco.cidade", endereco.cidade.toUpperCase());
    data.append("endereco.telefone", endereco.telefone);
    data.append("endereco.email", endereco.email.toLowerCase());
    if (payload.arquivos) {
        payload.arquivos.forEach(function (arquivo, index) {
            data.append("arquivos[".concat(index, "].tipo"), "".concat(arquivo.tipo).toUpperCase());
            data.append("arquivos[".concat(index, "].file"), arquivo.file);
        });
    }
    return data;
};
export var prepareResendRenovacaoPayload = function (payload) {
    var data = new FormData();
    data.append("id", payload.id);
    if (payload.arquivos) {
        payload.arquivos.forEach(function (arquivo, index) {
            data.append("arquivos[".concat(index, "].tipo"), "".concat(arquivo.tipo).toUpperCase());
            data.append("arquivos[".concat(index, "].file"), arquivo.file);
        });
    }
    return data;
};
