var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { zodResolver } from "@hookform/resolvers/zod";
import { Alert, AlertTitle, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { updatePhotoFormSchema, } from "../../forms/updatePhoto";
import { createApi } from "../../apis/seid";
import { isAxiosError } from "axios";
import RawFileInput from "../../components/RawFileInput";
var UpdatePhotoDialog = function (_a) {
    var onClose = _a.onClose, open = _a.open, token = _a.token, isPut = _a.isPut;
    var _b = useState("idle"), status = _b[0], setStatus = _b[1];
    var _c = useState(""), errorMessage = _c[0], setErrorMessage = _c[1];
    var form = useForm({
        defaultValues: { foto: undefined, identificacao: undefined },
        resolver: zodResolver(updatePhotoFormSchema),
    });
    var isSending = status === "loading" || form.formState.isSubmitting;
    var handleOnClose = function () {
        form.reset();
        onClose();
    };
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var api, files, payload, result, e_1;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    api = createApi(token);
                    files = [
                        { raw: data.foto, type: "FOTO" },
                        { raw: data.identificacao, type: "IDENTIFICACAO" },
                    ];
                    payload = new FormData();
                    files.forEach(function (file, index) {
                        payload.append("arquivos[".concat(index, "].tipo"), "".concat(file.type));
                        payload.append("arquivos[".concat(index, "].file"), file.raw);
                    });
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 6, , 7]);
                    setStatus("loading");
                    if (!isPut) return [3 /*break*/, 3];
                    return [4 /*yield*/, api.put("/atualizacao-cadastral/foto/reenviar", payload)];
                case 2:
                    result = _c.sent();
                    return [3 /*break*/, 5];
                case 3: return [4 /*yield*/, api.post("/atualizacao-cadastral/foto", payload)];
                case 4:
                    result = _c.sent();
                    _c.label = 5;
                case 5: return [3 /*break*/, 7];
                case 6:
                    e_1 = _c.sent();
                    result = e_1;
                    return [3 /*break*/, 7];
                case 7:
                    if (isAxiosError(result)) {
                        if ((_a = result.response) === null || _a === void 0 ? void 0 : _a.data.message)
                            setErrorMessage((_b = result.response) === null || _b === void 0 ? void 0 : _b.data.message);
                        setStatus("error");
                    }
                    else {
                        setStatus("success");
                        handleOnClose();
                        window.location.reload();
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(Dialog, __assign({ open: open, onClose: onClose }, { children: _jsxs("form", { children: [_jsx(DialogTitle, { children: "Atualiza\u00E7\u00E3o da foto de identifica\u00E7\u00E3o" }), _jsxs(DialogContent, { children: [_jsx(DialogContentText, { children: "Preencha os campos abaixo para seguir com a atualiza\u00E7\u00E3o da foto." }), _jsxs(Stack, __assign({ spacing: 2, sx: { mt: 2 } }, { children: [_jsx(DialogContentText, { children: "A foto deve seguir os seguintes padr\u00F5es:" }), _jsxs(Box, __assign({ component: "ul", sx: { color: "rgba(0, 0, 0, 0.6)" } }, { children: [_jsx("li", { children: "Fundo com cor neutra (se poss\u00EDvel na cor branca)" }), _jsxs("li", { children: ["Usar camisa escura, com cor diferente do fundo,", _jsx("br", {}), "para que haja contraste"] }), _jsx("li", { children: "N\u00E3o enviar foto tremida, emba\u00E7ada ou escura" }), _jsxs("li", { children: ["Enquadramento de foto no estilo 3x4 na altura", _jsx("br", {}), "dos olhos, olhando para a c\u00E2mera"] })] })), _jsx(DialogContentText, { children: "Acrescente o documento de identifica\u00E7\u00E3o dos seus acompanhantes caso haja." })] })), status === "error" && (_jsxs(Alert, __assign({ severity: "error", sx: { my: 2 } }, { children: [_jsx(AlertTitle, { children: "Erro ao enviar atualiza\u00E7\u00E3o" }), errorMessage ? (_jsx("p", { children: errorMessage })) : (_jsx("p", { children: "Ocorreu um error ao enviar esta atualiza\u00E7\u00E3o! Tente novamente mais tarde." }))] }))), _jsxs(Stack, __assign({ spacing: 2, sx: { mt: 3 } }, { children: [_jsx(Controller, { control: form.control, name: "foto", render: function (_a) {
                                        var _b;
                                        var field = _a.field, fieldState = _a.fieldState;
                                        return (_jsx(RawFileInput, { fullWidth: true, variant: "outlined", label: "Foto", placeholder: "Clique para anexar", size: "small", getInputText: function (value) { return value.name; }, error: !!fieldState.error, helperText: fieldState.error ? fieldState.error.message : " ", value: (_b = field.value) !== null && _b !== void 0 ? _b : null, onChange: function (value) {
                                                field.onChange(value);
                                            }, onBlur: field.onBlur, inputProps: { accept: "image/*" }, disabled: isSending }));
                                    } }), _jsx(Controller, { control: form.control, name: "identificacao", render: function (_a) {
                                        var _b;
                                        var field = _a.field, fieldState = _a.fieldState;
                                        return (_jsx(RawFileInput, { fullWidth: true, variant: "outlined", label: "Documento de identifica\u00E7\u00E3o", placeholder: "Clique para anexar", size: "small", getInputText: function (value) { return value.name; }, error: !!fieldState.error, helperText: fieldState.error ? fieldState.error.message : " ", value: (_b = field.value) !== null && _b !== void 0 ? _b : null, onChange: function (value) {
                                                field.onChange(value);
                                            }, onBlur: field.onBlur, disabled: isSending }));
                                    } })] }))] }), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ variant: "outlined", onClick: handleOnClose, disabled: isSending }, { children: "Fechar" })), _jsx(Button, __assign({ type: "button", variant: "contained", onClick: form.handleSubmit(onSubmit), disabled: isSending }, { children: isSending ? (_jsxs(_Fragment, { children: [_jsx(CircularProgress, { sx: { mr: 2 }, size: "1.25rem", color: "inherit" }), "Enviando"] })) : (_jsx(_Fragment, { children: "Enviar" })) }))] })] }) })));
};
export default UpdatePhotoDialog;
