var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack } from "@mui/material";
import { RouterManager } from "./modules/router/RouterManager";
import Loading from "./components/Loading";
import FeedbackDialog from "./components/FeedbackDialog";
export var App = function () {
    return (_jsxs(Stack, __assign({ direction: "column" }, { children: [_jsx(RouterManager, {}), _jsx(Loading, {}), _jsx(FeedbackDialog, {})] })));
};
