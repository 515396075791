var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Cancel, CheckCircle, Info, Report } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, } from "@mui/material";
import { useCallback, useMemo } from "react";
import { create } from "zustand";
var useFeedbackDialogStore = create(function (set, get) { return ({
    isOpen: false,
    title: "",
    descriptions: [],
    severity: "success",
    onClose: function () { return set({ isOpen: false }); },
    openDialog: function (_a) {
        var descriptions = _a.descriptions, severity = _a.severity, title = _a.title, onCloseClick = _a.onCloseClick;
        return set({ isOpen: true, title: title, descriptions: descriptions, severity: severity, onCloseClick: onCloseClick });
    },
}); });
export var useFeedbackDialog = function () {
    var openDialog = useFeedbackDialogStore(function (state) { return state.openDialog; });
    var result = useMemo(function () { return ({
        success: function (_a) {
            var title = _a.title, descriptions = _a.descriptions, onCloseClick = _a.onCloseClick;
            return openDialog({ severity: "success", title: title, descriptions: descriptions, onCloseClick: onCloseClick });
        },
        info: function (_a) {
            var title = _a.title, descriptions = _a.descriptions, onCloseClick = _a.onCloseClick;
            return openDialog({ severity: "info", title: title, descriptions: descriptions, onCloseClick: onCloseClick });
        },
        warning: function (_a) {
            var title = _a.title, descriptions = _a.descriptions, onCloseClick = _a.onCloseClick;
            return openDialog({ severity: "warning", title: title, descriptions: descriptions, onCloseClick: onCloseClick });
        },
        error: function (_a) {
            var title = _a.title, descriptions = _a.descriptions, onCloseClick = _a.onCloseClick;
            return openDialog({ severity: "error", title: title, descriptions: descriptions, onCloseClick: onCloseClick });
        },
    }); }, [openDialog]);
    return result;
};
export default function FeedbackDialog() {
    var isOpen = useFeedbackDialogStore(function (state) { return state.isOpen; });
    var title = useFeedbackDialogStore(function (state) { return state.title; });
    var descriptions = useFeedbackDialogStore(function (state) { return state.descriptions; });
    var severity = useFeedbackDialogStore(function (state) { return state.severity; });
    var onClose = useFeedbackDialogStore(function (state) { return state.onClose; });
    var onCloseClick = useFeedbackDialogStore(function (state) { return state.onCloseClick; });
    var handleOnCloseClick = useCallback(function () {
        if (onCloseClick)
            onCloseClick();
        onClose();
    }, [onCloseClick, onClose]);
    var renderIconBySeverity = {
        error: _jsx(Cancel, { sx: { width: 32, height: 32, color: "#EF4123", mr: 1 } }),
        warning: _jsx(Report, { sx: { width: 32, height: 32, color: "#FDB913", mr: 1 } }),
        success: (_jsx(CheckCircle, { sx: { width: 32, height: 32, color: "#007932", mr: 1 } })),
        info: _jsx(Info, { sx: { width: 32, height: 32, color: "#034EA2", mr: 1 } }),
    };
    return (_jsxs(Dialog, __assign({ onClose: onClose, open: isOpen, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description" }, { children: [_jsxs(DialogTitle, __assign({ id: "alert-dialog-title", sx: { display: "flex", alignItems: "center" } }, { children: [renderIconBySeverity[severity], title] })), _jsx(DialogContent, { children: descriptions.map(function (description, index) { return (_jsx(DialogContentText, __assign({ id: "alert-dialog-description" }, { children: description }), index)); }) }), _jsx(DialogActions, { children: _jsx(Button, __assign({ onClick: handleOnCloseClick }, { children: "Fechar" })) })] })));
}
