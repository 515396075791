var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect } from "react";
import { useWebgate } from "@xvia/webgate-connect";
import { useHistory } from "react-router-dom";
import { isAxiosError } from "axios";
import { useLoading } from "../../components/Loading";
import { getSolicitation, prepareCreateSolicitacaoPayload, } from "../../services/api/seid";
import { Situacao } from "../../models";
import { useFeedbackDialog } from "../../components/FeedbackDialog";
import { DEFAULT_ERROR_MESSAGE } from "../../util/constants";
import BackButton from "../../components/BackButton";
import { Paper, Typography } from "@mui/material";
import SolicitationStepper from "../../modules/form-steps/SolicitationStepper";
import { createSolicitation } from "../../services/api/seid";
import { useStepperStore } from "../../stores/stepper";
import { prettifyDocumentErrorMessage } from "../../util/string";
export default function Solicitacao() {
    var _this = this;
    var _a, _b, _c;
    var _d = useWebgate(), userInfo = _d.userInfo, accessToken = _d.accessToken;
    var history = useHistory();
    var loading = useLoading();
    var feedbackDialog = useFeedbackDialog();
    var resetAllData = useStepperStore(function (state) { return state.resetAllData; });
    useEffect(function () {
        var handleRedirect = function () { return __awaiter(_this, void 0, void 0, function () {
            var solicitationResult;
            var _a, _b, _c, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        if (!accessToken) return [3 /*break*/, 2];
                        loading.show();
                        return [4 /*yield*/, getSolicitation(accessToken)];
                    case 1:
                        solicitationResult = _e.sent();
                        loading.hide();
                        if (isAxiosError(solicitationResult)) {
                            if (((_a = solicitationResult.response) === null || _a === void 0 ? void 0 : _a.status) === 409) {
                                history.replace("/carteira");
                            }
                            else if (((_b = solicitationResult.response) === null || _b === void 0 ? void 0 : _b.status) >= 500) {
                                feedbackDialog.error({
                                    title: "Aconteceu um erro!",
                                    descriptions: [
                                        (_d = (_c = solicitationResult.response) === null || _c === void 0 ? void 0 : _c.data.message) !== null && _d !== void 0 ? _d : DEFAULT_ERROR_MESSAGE,
                                    ],
                                    onCloseClick: function () { return window.history.back(); },
                                });
                            }
                        }
                        else if (solicitationResult.data.situacao === Situacao.EmAnalise ||
                            solicitationResult.data.situacao === Situacao.Pendente ||
                            solicitationResult.data.situacao === Situacao.Indeferido) {
                            history.replace("/solicitacao/editar");
                        }
                        _e.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        handleRedirect();
    }, [accessToken, feedbackDialog, history, loading]);
    var handleFormSubmit = useCallback(function (data) { return __awaiter(_this, void 0, void 0, function () {
        var preparedData, createSolicitationResult, errorTitle, isDocumentErrorMessage;
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    loading.show();
                    preparedData = prepareCreateSolicitacaoPayload(data);
                    return [4 /*yield*/, createSolicitation(accessToken, preparedData)];
                case 1:
                    createSolicitationResult = _e.sent();
                    loading.hide();
                    if (isAxiosError(createSolicitationResult)) {
                        errorTitle = "Aconteceu um erro!";
                        isDocumentErrorMessage = (_a = createSolicitationResult.response) === null || _a === void 0 ? void 0 : _a.data.status.includes("DOCUMENTOS");
                        if (isDocumentErrorMessage) {
                            feedbackDialog.error({
                                title: errorTitle,
                                descriptions: __spreadArray([
                                    "Os seguintes documentos são obrigatórios:"
                                ], prettifyDocumentErrorMessage((_b = createSolicitationResult.response) === null || _b === void 0 ? void 0 : _b.data.message), true),
                            });
                        }
                        else {
                            feedbackDialog.error({
                                title: errorTitle,
                                descriptions: [
                                    (_d = (_c = createSolicitationResult.response) === null || _c === void 0 ? void 0 : _c.data.message) !== null && _d !== void 0 ? _d : DEFAULT_ERROR_MESSAGE,
                                ],
                                onCloseClick: function () { return window.history.back(); },
                            });
                        }
                    }
                    else {
                        feedbackDialog.success({
                            title: "Solicitação feita com sucesso",
                            descriptions: [
                                "Ao fechar você será redirecionado para a tela principal.",
                            ],
                            onCloseClick: function () {
                                resetAllData();
                                window.location.reload();
                            },
                        });
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [accessToken, feedbackDialog, loading, resetAllData]);
    return (_jsxs(_Fragment, { children: [_jsx(BackButton, {}), _jsxs(Paper, __assign({ sx: function (theme) { return ({ my: theme.spacing(2), p: theme.spacing(2) }); } }, { children: [_jsx(Typography, __assign({ component: "h1", sx: function (theme) { return ({
                            mb: theme.spacing(2),
                            fontWeight: 700,
                            fontSize: "1.5rem",
                            color: theme.palette.primary.dark,
                        }); } }, { children: "Solicita\u00E7\u00E3o - Carteira de Identifica\u00E7\u00E3o do Autista" })), _jsx(SolicitationStepper, { defaultValues: {
                            beneficiary: {
                                nome: ((_a = userInfo === null || userInfo === void 0 ? void 0 : userInfo.name) === null || _a === void 0 ? void 0 : _a.trim()) || "",
                                cpf: ((_b = userInfo === null || userInfo === void 0 ? void 0 : userInfo.preferred_username) === null || _b === void 0 ? void 0 : _b.trim()) || "",
                                rg: "",
                                rgOrgaoExpedidor: "",
                                rgUf: "",
                                sexo: "",
                                nacionalidade: "",
                                naturalidadeUf: "",
                                naturalidade: "",
                                mae: "",
                                pai: "",
                                email: ((_c = userInfo === null || userInfo === void 0 ? void 0 : userInfo.email) === null || _c === void 0 ? void 0 : _c.trim()) || "",
                                fone1: "",
                            },
                            address: {
                                cep: "",
                                logradouro: "",
                                numero: "",
                                bairro: "",
                                complemento: "",
                                uf: "",
                                cidade: "",
                            },
                            documents: {
                                termoDeResponsabilidade: undefined,
                            },
                        }, onFormSubmit: handleFormSubmit, token: accessToken })] }))] }));
}
