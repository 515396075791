var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Stack, } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { documentsFormSchema, resendDocumentsFormSchema, } from "../../forms/documents";
import { useStepperStore } from "../../stores/stepper";
import { formatDateToSend } from "../../util/date";
import PictureInfoDialog from "../../components/PictureInfoDialog";
import { useFilesStore } from "../../stores/files";
import FilesResults from "../../components/FilesResults";
var DocumentsForm = function (_a) {
    var _b = _a.id, id = _b === void 0 ? "" : _b, _c = _a.mode, mode = _c === void 0 ? "create" : _c, defaultValues = _a.defaultValues, _d = _a.disableSubmit, disableSubmit = _d === void 0 ? false : _d, _e = _a.token, token = _e === void 0 ? "" : _e, onFormSubmit = _a.onFormSubmit, documents = _a.documents;
    var _f = useState(false), openPictureInfoDialog = _f[0], setOpenPictureInfoDialog = _f[1];
    var beneficiaryData = useStepperStore(function (state) { return state.beneficiaryData; });
    var addressData = useStepperStore(function (state) { return state.addressData; });
    var documentsData = useStepperStore(function (state) { return state.documentsData; });
    var setDocumentsData = useStepperStore(function (state) { return state.setDocumentsData; });
    var goToPreviousStep = useStepperStore(function (state) { return state.goToPreviousStep; });
    var setIsActiveStepValid = useStepperStore(function (state) { return state.setIsActiveStepValid; });
    var isEditMode = mode === "edit";
    var isRenewMode = mode === "renew";
    var files = useFilesStore(function (state) { return state.files; });
    var form = useForm({
        defaultValues: documentsData !== null && documentsData !== void 0 ? documentsData : defaultValues,
        resolver: zodResolver(isEditMode ? resendDocumentsFormSchema : documentsFormSchema),
    });
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setDocumentsData(data);
                    setIsActiveStepValid(true);
                    return [4 /*yield*/, onFormSubmit({
                            id: id,
                            pessoa: {
                                nome: beneficiaryData === null || beneficiaryData === void 0 ? void 0 : beneficiaryData.nome,
                                cpf: beneficiaryData === null || beneficiaryData === void 0 ? void 0 : beneficiaryData.cpf,
                                rgDataExpedicao: formatDateToSend(beneficiaryData === null || beneficiaryData === void 0 ? void 0 : beneficiaryData.rgDataExpedicao),
                                rgOrgaoExpedidor: beneficiaryData === null || beneficiaryData === void 0 ? void 0 : beneficiaryData.rgOrgaoExpedidor,
                                rgUf: beneficiaryData === null || beneficiaryData === void 0 ? void 0 : beneficiaryData.rgUf,
                                rg: beneficiaryData === null || beneficiaryData === void 0 ? void 0 : beneficiaryData.rg,
                                sexo: beneficiaryData === null || beneficiaryData === void 0 ? void 0 : beneficiaryData.sexo,
                                naturalidade: beneficiaryData === null || beneficiaryData === void 0 ? void 0 : beneficiaryData.naturalidade,
                                nacionalidade: beneficiaryData === null || beneficiaryData === void 0 ? void 0 : beneficiaryData.nacionalidade,
                                dataNascimento: formatDateToSend(beneficiaryData === null || beneficiaryData === void 0 ? void 0 : beneficiaryData.dataNascimento),
                                mae: beneficiaryData === null || beneficiaryData === void 0 ? void 0 : beneficiaryData.mae,
                                pai: beneficiaryData === null || beneficiaryData === void 0 ? void 0 : beneficiaryData.pai,
                            },
                            endereco: {
                                uf: addressData === null || addressData === void 0 ? void 0 : addressData.uf,
                                bairro: addressData === null || addressData === void 0 ? void 0 : addressData.bairro,
                                cep: addressData === null || addressData === void 0 ? void 0 : addressData.cep,
                                cidade: addressData === null || addressData === void 0 ? void 0 : addressData.cidade,
                                complemento: addressData === null || addressData === void 0 ? void 0 : addressData.complemento,
                                email: beneficiaryData === null || beneficiaryData === void 0 ? void 0 : beneficiaryData.email,
                                telefone: beneficiaryData === null || beneficiaryData === void 0 ? void 0 : beneficiaryData.fone1,
                                logradouro: addressData === null || addressData === void 0 ? void 0 : addressData.logradouro,
                                numero: Number(addressData === null || addressData === void 0 ? void 0 : addressData.numero),
                            },
                            arquivos: data.arquivos,
                            termoDeResponsabilidade: data.termoDeResponsabilidade,
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var onBack = function () {
        var currentFormValues = form.getValues();
        setDocumentsData(currentFormValues);
        goToPreviousStep();
    };
    useEffect(function () {
        setIsActiveStepValid(form.formState.isSubmitted && !form.formState.isValid);
    }, [form.formState, setIsActiveStepValid]);
    useEffect(function () {
        if (files === null || files === void 0 ? void 0 : files.length) {
            var fileList = files.map(function (file) { return ({
                descricao: file.description,
                tipo: "".concat(file.type),
                file: file.file,
            }); });
            form.setValue("arquivos", fileList, {
                shouldTouch: true,
                shouldValidate: true,
            });
        }
        if (!files) {
            form.setValue("arquivos", [], {
                shouldTouch: true,
                shouldValidate: true,
            });
        }
    }, [files, form]);
    return (_jsxs(_Fragment, { children: [_jsxs("form", __assign({ onSubmit: form.handleSubmit(onSubmit), autoComplete: "off" }, { children: [_jsxs(Stack, __assign({ spacing: 4 }, { children: [_jsx(FilesResults, { error: !!form.formState.errors.arquivos, token: token, disabled: disableSubmit, mode: mode, documents: documents }), _jsx(Controller, { control: form.control, name: "termoDeResponsabilidade", defaultValue: isEditMode || isRenewMode ? true : undefined, render: function (_a) {
                                    var field = _a.field, fieldState = _a.fieldState;
                                    return isEditMode || isRenewMode ? (_jsx("input", __assign({}, field, { type: "hidden", value: "true" }))) : (_jsxs(FormControl, __assign({ component: "fieldset", variant: "standard", error: !!fieldState.error, sx: function (theme) { return ({
                                            mt: theme.spacing(1),
                                        }); } }, { children: [_jsx(FormLabel, __assign({ component: "legend", sx: { mb: 1 } }, { children: "Termo de Responsabilidade" })), _jsx(FormGroup, { children: _jsx(FormControlLabel, { control: _jsx(Checkbox, __assign({ defaultChecked: false }, field)), label: "Afirmo, sob as penas da lei, que as informa\u00E7\u00F5es acima s\u00E3o verdadeiras e de minha exclusiva responsabilidade. Em observ\u00E2ncia \u00E0 Lei n\u00B0 13.709/18" }) }), _jsx(FormHelperText, { children: fieldState.error ? fieldState.error.message : " " })] })));
                                } })] })), _jsxs(Box, __assign({ sx: { display: "flex", flexDirection: "row", pt: 2 } }, { children: [_jsx(Button, __assign({ color: "primary", onClick: onBack, sx: { mr: 1 } }, { children: "Voltar" })), _jsx(Box, { sx: { flex: "1 1 auto" } }), _jsx(Button, __assign({ variant: "contained", type: "submit", disabled: disableSubmit || form.formState.isSubmitting }, { children: "Enviar" }))] }))] })), _jsx(PictureInfoDialog, { open: openPictureInfoDialog, onClose: function () { return setOpenPictureInfoDialog(false); } })] }));
};
export default DocumentsForm;
