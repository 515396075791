var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, StyledEngineProvider, CssBaseline, } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { ptBR as localePtBR } from "@mui/x-date-pickers/locales";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ptBR from "date-fns/locale/pt-BR";
import { theme } from "pigov-design-system";
import { WebgateProvider as _WebgateProvider } from "@xvia/webgate-connect";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ResponsiveIndicator from "./modules/ui/ResponsiveIndicator";
var WebgateProvider = _WebgateProvider;
var queryClient = new QueryClient();
var baseName = window.__APP_BASE__ || "/";
var root = ReactDOM.createRoot(document.getElementById("root"));
root.render(_jsxs(React.StrictMode, { children: [_jsx(BrowserRouter, __assign({ basename: baseName }, { children: _jsx(WebgateProvider, __assign({ portalUrl: process.env.PORTAL_URL }, { children: _jsx(QueryClientProvider, __assign({ client: queryClient }, { children: _jsx(ThemeProvider, __assign({ theme: theme }, { children: _jsx(StyledEngineProvider, __assign({ injectFirst: true }, { children: _jsxs(LocalizationProvider, __assign({ dateAdapter: AdapterDateFns, adapterLocale: ptBR, localeText: localePtBR.components.MuiLocalizationProvider.defaultProps
                                    .localeText }, { children: [_jsx(CssBaseline, {}), _jsx(App, {})] })) })) })) })) })) })), _jsx(ResponsiveIndicator, {})] }));
