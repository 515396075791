var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, } from "@mui/material";
var PictureInfoDialog = function (_a) {
    var open = _a.open, onClose = _a.onClose;
    var handlePictureInfoDialogClose = function () {
        onClose();
    };
    return (_jsxs(Dialog, __assign({ open: open, onClose: handlePictureInfoDialogClose, "aria-labelledby": "feedback-dialog-title", "aria-describedby": "feedback-dialog-description" }, { children: [_jsx(DialogTitle, __assign({ id: "feedback-dialog-title", sx: {
                    display: "flex",
                    alignItems: "center",
                } }, { children: "A foto deve seguir os seguintes padr\u00F5es:" })), _jsxs(DialogContent, __assign({ sx: {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                } }, { children: [_jsxs("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "96", height: "96", viewBox: "0 0 24 24", fill: "none", stroke: "#034EA1", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round", className: "lucide lucide-scan-face" }, { children: [_jsx("path", { d: "M3 7V5a2 2 0 0 1 2-2h2" }), _jsx("path", { d: "M17 3h2a2 2 0 0 1 2 2v2" }), _jsx("path", { d: "M21 17v2a2 2 0 0 1-2 2h-2" }), _jsx("path", { d: "M7 21H5a2 2 0 0 1-2-2v-2" }), _jsx("path", { d: "M8 14s1.5 2 4 2 4-2 4-2" }), _jsx("path", { d: "M9 9h.01" }), _jsx("path", { d: "M15 9h.01" })] })), _jsxs(Box, __assign({ component: "ul", sx: { mt: 2 }, id: "feedback-dialog-description" }, { children: [_jsx("li", { children: "Fundo com cor neutra (se poss\u00EDvel na cor branca)" }), _jsxs("li", { children: ["Usar camisa escura, com cor diferente do fundo,", _jsx("br", {}), "para que haja contraste"] }), _jsx("li", { children: "N\u00E3o enviar foto tremida, emba\u00E7ada ou escura" }), _jsxs("li", { children: ["Enquadramento de foto no estilo 3x4 na altura", _jsx("br", {}), "dos olhos, olhando para a c\u00E2mera"] })] }))] })), _jsx(DialogActions, { children: _jsx(Button, __assign({ variant: "contained", onClick: handlePictureInfoDialogClose, autoFocus: true }, { children: "Ok Entendi" })) })] })));
};
export default PictureInfoDialog;
