import * as z from "zod";
import { requiredFieldMessage, requiredFileValue } from "../util/validation";
export var fileFormSchema = z
    .object({
    type: z.string().min(1, requiredFieldMessage),
    file: requiredFileValue.refine(function (file) {
        var allowedFilesRegExp = new RegExp(/(png|jpg|jpeg|pdf)/);
        return allowedFilesRegExp.test(file.name);
    }, "Formato de arquivo não permitido"),
})
    .refine(function (values) {
    if (values.type === "FOTO" && values.file.name.includes(".pdf")) {
        return false;
    }
    return true;
}, {
    message: "Não é permitido o envio de foto no formato PDF",
    path: ["file"],
});
