import * as z from "zod";
import { MAX_FILE_SIZE } from "./constants";
export var zodStringOptionalOrEmpty = z.string().optional().or(z.literal(""));
export var requiredFieldMessage = "Campo obrigatório";
export var maxFileSizeMessage = "O tamanho máximo da arquivo é de 10 MB";
export var emptySelectRefinementCheck = function (value) { return value !== ""; };
export var requiredFileValue = z
    .instanceof(File, {
    message: requiredFieldMessage,
})
    .refine(function (file) { return file.size < MAX_FILE_SIZE; }, maxFileSizeMessage);
export var optionalFileValue = z
    .instanceof(File)
    .refine(function (file) { return file.size < MAX_FILE_SIZE; }, maxFileSizeMessage)
    .optional()
    .or(z.literal(null));
