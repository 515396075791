var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from "react";
import { useIMask } from "react-imask";
import { Box, Button, MenuItem, Stack, TextField } from "@mui/material";
import { DateField } from "@mui/x-date-pickers";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { GENDERS_OPTIONS, UFS } from "../../util/constants";
import { beneficiaryFormSchema, } from "../../forms/beneficiary";
import { useStepperStore } from "../../stores/stepper";
import { useLoading } from "../../components/Loading";
import { getNacionalidades, getNaturalidadeByUf, } from "../../services/api/seid";
import { isAxiosError } from "axios";
import { useDocumentFieldsStore } from "../../stores/documentFields";
var BeneficiaryForm = function (_a) {
    var disabled = _a.disabled, defaultValues = _a.defaultValues, token = _a.token, mode = _a.mode;
    var loading = useLoading();
    var isEditMode = useMemo(function () { return mode === "edit"; }, [mode]);
    var isRenewMode = useMemo(function () { return mode === "renew"; }, [mode]);
    var _b = useState([]), nacionalidades = _b[0], setNacionalidades = _b[1];
    var _c = useState([]), naturalidades = _c[0], setNaturaldiades = _c[1];
    var beneficiaryData = useStepperStore(function (state) { return state.beneficiaryData; });
    var setBeneficiaryData = useStepperStore(function (state) { return state.setBeneficiaryData; });
    var goToNextStep = useStepperStore(function (state) { return state.goToNextStep; });
    var setIsActiveStepValid = useStepperStore(function (state) { return state.setIsActiveStepValid; });
    var enableIdentificationFields = useDocumentFieldsStore(function (state) { return state.enableIdentificationFields; });
    var cpfMask = useIMask({ mask: "000.000.000-00" });
    var rgMask = useIMask({ mask: "00000000000000" });
    var phoneMask = useIMask({ mask: "(00) {9}0000-0000" });
    var form = useForm({
        defaultValues: beneficiaryData !== null && beneficiaryData !== void 0 ? beneficiaryData : defaultValues,
        resolver: zodResolver(beneficiaryFormSchema),
    });
    var naturalidadeUfValue = form.watch("naturalidadeUf");
    useEffect(function () {
        var getNacionalidadeData = function () { return __awaiter(void 0, void 0, void 0, function () {
            var nacionalidadesResult;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loading.show();
                        return [4 /*yield*/, getNacionalidades()];
                    case 1:
                        nacionalidadesResult = _a.sent();
                        loading.hide();
                        if (!isAxiosError(nacionalidadesResult))
                            setNacionalidades(nacionalidadesResult.list);
                        return [2 /*return*/];
                }
            });
        }); };
        getNacionalidadeData();
    }, [loading]);
    useEffect(function () {
        var getNaturalidadeData = function () { return __awaiter(void 0, void 0, void 0, function () {
            var cidadesResult;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!naturalidadeUfValue) return [3 /*break*/, 2];
                        loading.show();
                        return [4 /*yield*/, getNaturalidadeByUf(naturalidadeUfValue)()];
                    case 1:
                        cidadesResult = _a.sent();
                        loading.hide();
                        if (!isAxiosError(cidadesResult))
                            setNaturaldiades(cidadesResult.list);
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        getNaturalidadeData();
    }, [loading, naturalidadeUfValue]);
    useEffect(function () {
        form.setValue("naturalidade", "");
        if (defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.naturalidade) {
            form.setValue("naturalidade", defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.naturalidade);
        }
        if (beneficiaryData === null || beneficiaryData === void 0 ? void 0 : beneficiaryData.naturalidade) {
            form.setValue("naturalidade", beneficiaryData === null || beneficiaryData === void 0 ? void 0 : beneficiaryData.naturalidade);
        }
    }, [
        naturalidadeUfValue,
        form,
        defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.naturalidade,
        beneficiaryData === null || beneficiaryData === void 0 ? void 0 : beneficiaryData.naturalidade,
    ]);
    useEffect(function () {
        if ((defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.cpf) && cpfMask.ref.current)
            cpfMask.setUnmaskedValue(defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.cpf);
    }, [defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.cpf, cpfMask.ref.current]);
    useEffect(function () {
        if ((defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.rg) && rgMask.ref.current)
            rgMask.setUnmaskedValue(defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.rg);
    }, [defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.rg, rgMask.ref.current]);
    useEffect(function () {
        if ((defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.fone1) && phoneMask.ref.current) {
            phoneMask.setUnmaskedValue(defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.fone1);
        }
    }, [defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.fone1, phoneMask.ref.current]);
    useEffect(function () {
        if (beneficiaryData) {
            if (beneficiaryData === null || beneficiaryData === void 0 ? void 0 : beneficiaryData.cpf)
                cpfMask.setUnmaskedValue(beneficiaryData === null || beneficiaryData === void 0 ? void 0 : beneficiaryData.cpf);
            if (beneficiaryData === null || beneficiaryData === void 0 ? void 0 : beneficiaryData.rg)
                rgMask.setUnmaskedValue(beneficiaryData === null || beneficiaryData === void 0 ? void 0 : beneficiaryData.rg);
            if (beneficiaryData === null || beneficiaryData === void 0 ? void 0 : beneficiaryData.fone1)
                phoneMask.setUnmaskedValue(beneficiaryData === null || beneficiaryData === void 0 ? void 0 : beneficiaryData.fone1);
        }
    }, [beneficiaryData]);
    useEffect(function () {
        setIsActiveStepValid(form.formState.isSubmitted && !form.formState.isValid);
    }, [form.formState, setIsActiveStepValid]);
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setBeneficiaryData({
                cpf: cpfMask.unmaskedValue,
                dataNascimento: data.dataNascimento,
                email: data.email,
                fone1: phoneMask.unmaskedValue,
                mae: data.mae,
                nacionalidade: data.nacionalidade,
                naturalidade: data.naturalidade,
                naturalidadeUf: data.naturalidadeUf,
                nome: data.nome,
                rg: rgMask.unmaskedValue,
                rgDataExpedicao: data.rgDataExpedicao,
                rgOrgaoExpedidor: data.rgOrgaoExpedidor,
                rgUf: data.rgUf,
                sexo: data.sexo,
                pai: data.pai,
            });
            goToNextStep();
            setIsActiveStepValid(true);
            return [2 /*return*/];
        });
    }); };
    return (_jsxs("form", __assign({ onSubmit: form.handleSubmit(onSubmit), autoComplete: "off" }, { children: [_jsxs(Stack, __assign({ spacing: 2 }, { children: [_jsx(Controller, { control: form.control, name: "nome", render: function (_a) {
                            var field = _a.field, fieldState = _a.fieldState;
                            return (_jsx(TextField, __assign({ variant: "outlined", label: "Nome Completo", size: "small", error: !!fieldState.error, helperText: fieldState.error ? fieldState.error.message : " ", sx: { "& input": { textTransform: "uppercase" } } }, field, { disabled: true })));
                        } }), _jsx(Controller, { control: form.control, name: "cpf", render: function (_a) {
                            var field = _a.field, fieldState = _a.fieldState;
                            return (_jsx(TextField, { variant: "outlined", label: "CPF", size: "small", error: !!fieldState.error, helperText: fieldState.error ? fieldState.error.message : " ", inputRef: cpfMask.ref, value: cpfMask.value, onChange: field.onChange, onBlur: field.onBlur, sx: { "& input": { textTransform: "uppercase" } }, disabled: true, inputProps: { form: { autocomplete: "off" } } }));
                        } }), _jsxs(Stack, __assign({ direction: { xs: "column", md: "row" }, spacing: 2 }, { children: [_jsx(Controller, { control: form.control, name: "rg", render: function (_a) {
                                    var field = _a.field, fieldState = _a.fieldState;
                                    return (_jsx(TextField, { variant: "outlined", label: "RG", size: "small", error: !!fieldState.error, helperText: fieldState.error ? fieldState.error.message : " ", inputRef: rgMask.ref, value: rgMask.value, onChange: field.onChange, onBlur: field.onBlur, sx: {
                                            flex: 2.5,
                                            "& input": { textTransform: "uppercase" },
                                        }, disabled: isEditMode
                                            ? disabled && !enableIdentificationFields
                                            : disabled && !isRenewMode, inputProps: { form: { autocomplete: "off" } } }));
                                } }), _jsx(Controller, { control: form.control, name: "rgUf", render: function (_a) {
                                    var field = _a.field, fieldState = _a.fieldState;
                                    return (_jsx(TextField, __assign({ select: true, variant: "outlined", label: "UF", size: "small", error: !!fieldState.error, helperText: fieldState.error ? fieldState.error.message : " ", sx: {
                                            flex: 1.5,
                                            "& input": { textTransform: "uppercase" },
                                        } }, field, { disabled: isEditMode
                                            ? disabled && !enableIdentificationFields
                                            : disabled && !isRenewMode }, { children: UFS.map(function (uf) { return (_jsx(MenuItem, __assign({ value: uf }, { children: uf }), uf)); }) })));
                                } })] })), _jsxs(Stack, __assign({ direction: { xs: "column", md: "row" }, spacing: 2 }, { children: [_jsx(Controller, { control: form.control, name: "rgOrgaoExpedidor", render: function (_a) {
                                    var field = _a.field, fieldState = _a.fieldState;
                                    return (_jsx(TextField, __assign({ variant: "outlined", label: "Org\u00E3o Expedidor", size: "small", error: !!fieldState.error, helperText: fieldState.error ? fieldState.error.message : " ", sx: { flex: 3, "& input": { textTransform: "uppercase" } } }, field, { disabled: isEditMode
                                            ? disabled && !enableIdentificationFields
                                            : disabled && !isRenewMode })));
                                } }), _jsx(Controller, { control: form.control, name: "rgDataExpedicao", render: function (_a) {
                                    var field = _a.field, fieldState = _a.fieldState;
                                    return (_jsx(DateField, { variant: "outlined", label: "Data de expedi\u00E7\u00E3o", size: "small", slotProps: {
                                            textField: {
                                                error: !!fieldState.error,
                                                helperText: fieldState.error
                                                    ? fieldState.error.message
                                                    : " ",
                                            },
                                        }, sx: { flex: 3, "& input": { textTransform: "uppercase" } }, value: field.value, onChange: function (value) { return field.onChange(value); }, onBlur: field.onBlur, disabled: isEditMode
                                            ? disabled && !enableIdentificationFields
                                            : disabled && !isRenewMode }));
                                } })] })), _jsx(Controller, { control: form.control, name: "dataNascimento", render: function (_a) {
                            var field = _a.field, fieldState = _a.fieldState;
                            return (_jsx(DateField, { variant: "outlined", label: "Data de nascimento", size: "small", slotProps: {
                                    textField: {
                                        error: !!fieldState.error,
                                        helperText: fieldState.error ? fieldState.error.message : " ",
                                    },
                                }, value: field.value, onChange: function (value) { return field.onChange(value); }, onBlur: field.onBlur, sx: { "& input": { textTransform: "uppercase" } }, disabled: isEditMode
                                    ? disabled && !enableIdentificationFields
                                    : disabled && !isRenewMode }));
                        } }), _jsx(Controller, { control: form.control, name: "sexo", render: function (_a) {
                            var field = _a.field, fieldState = _a.fieldState;
                            return (_jsx(TextField, __assign({ select: true, variant: "outlined", label: "Sexo", size: "small", error: !!fieldState.error, helperText: fieldState.error ? fieldState.error.message : " ", sx: { "& input": { textTransform: "uppercase" } } }, field, { disabled: isEditMode
                                    ? disabled && !enableIdentificationFields
                                    : disabled && !isRenewMode }, { children: GENDERS_OPTIONS.map(function (gender) { return (_jsx(MenuItem, __assign({ value: gender.value }, { children: gender.label }), gender.value)); }) })));
                        } }), _jsx(Controller, { control: form.control, name: "nacionalidade", render: function (_a) {
                            var field = _a.field, fieldState = _a.fieldState;
                            return (_jsx(TextField, __assign({ select: (nacionalidades === null || nacionalidades === void 0 ? void 0 : nacionalidades.length) !== 0, variant: "outlined", label: "Nacionalidade", size: "small", error: !!fieldState.error, helperText: fieldState.error ? fieldState.error.message : " ", sx: { "& input": { textTransform: "uppercase" } } }, field, { disabled: isEditMode
                                    ? (disabled || (nacionalidades === null || nacionalidades === void 0 ? void 0 : nacionalidades.length) === 0) &&
                                        !enableIdentificationFields
                                    : (disabled && !isRenewMode) || (nacionalidades === null || nacionalidades === void 0 ? void 0 : nacionalidades.length) === 0 }, { children: nacionalidades === null || nacionalidades === void 0 ? void 0 : nacionalidades.map(function (nacionalidade) { return (_jsx(MenuItem, __assign({ value: "".concat(nacionalidade.id) }, { children: nacionalidade.descricao }), nacionalidade.id)); }) })));
                        } }), _jsxs(Stack, __assign({ direction: { xs: "column", md: "row" }, spacing: 2 }, { children: [_jsx(Controller, { control: form.control, name: "naturalidadeUf", render: function (_a) {
                                    var field = _a.field, fieldState = _a.fieldState;
                                    return (_jsx(TextField, __assign({ select: true, variant: "outlined", label: "Naturalidade UF", size: "small", error: !!fieldState.error, helperText: fieldState.error ? fieldState.error.message : " ", sx: { flex: 1, "& input": { textTransform: "uppercase" } } }, field, { disabled: isEditMode
                                            ? disabled && !enableIdentificationFields
                                            : disabled && !isRenewMode }, { children: UFS.map(function (uf) { return (_jsx(MenuItem, __assign({ value: uf }, { children: uf }), uf)); }) })));
                                } }), _jsx(Controller, { control: form.control, name: "naturalidade", render: function (_a) {
                                    var _b;
                                    var field = _a.field, fieldState = _a.fieldState;
                                    return (_jsx(TextField, __assign({ select: (naturalidades === null || naturalidades === void 0 ? void 0 : naturalidades.length) !== 0, variant: "outlined", label: "Naturalidade Cidade", size: "small", error: !!fieldState.error, helperText: fieldState.error ? fieldState.error.message : " ", sx: { flex: 1, "& input": { textTransform: "uppercase" } } }, field, { disabled: isEditMode
                                            ? (disabled || (naturalidades === null || naturalidades === void 0 ? void 0 : naturalidades.length) === 0) &&
                                                !enableIdentificationFields
                                            : (disabled && !isRenewMode) || (naturalidades === null || naturalidades === void 0 ? void 0 : naturalidades.length) === 0 }, { children: (_b = naturalidades === null || naturalidades === void 0 ? void 0 : naturalidades.sort(function (current, next) {
                                            return current.descricao.localeCompare(next.descricao);
                                        })) === null || _b === void 0 ? void 0 : _b.map(function (cidade) {
                                            var _a;
                                            return (_jsx(MenuItem, __assign({ value: "".concat(cidade.id) }, { children: (_a = cidade.descricao) === null || _a === void 0 ? void 0 : _a.toUpperCase() }), cidade.id));
                                        }) })));
                                } })] })), _jsx(Controller, { control: form.control, name: "mae", render: function (_a) {
                            var field = _a.field, fieldState = _a.fieldState;
                            return (_jsx(TextField, __assign({ variant: "outlined", label: "Nome da m\u00E3e", size: "small", error: !!fieldState.error, helperText: fieldState.error ? fieldState.error.message : " ", sx: { "& input": { textTransform: "uppercase" } } }, field, { disabled: isEditMode
                                    ? disabled && !enableIdentificationFields
                                    : disabled && !isRenewMode })));
                        } }), _jsx(Controller, { control: form.control, name: "pai", render: function (_a) {
                            var field = _a.field, fieldState = _a.fieldState;
                            return (_jsx(TextField, __assign({ variant: "outlined", label: "Nome do pai", size: "small", error: !!fieldState.error, helperText: fieldState.error ? fieldState.error.message : " ", sx: { "& input": { textTransform: "uppercase" } } }, field, { disabled: isEditMode
                                    ? disabled && !enableIdentificationFields
                                    : disabled && !isRenewMode })));
                        } }), _jsx(Controller, { control: form.control, name: "email", render: function (_a) {
                            var field = _a.field, fieldState = _a.fieldState;
                            return (_jsx(TextField, __assign({ variant: "outlined", label: "E-mail", size: "small", error: !!fieldState.error, helperText: fieldState.error ? fieldState.error.message : " ", sx: { "& input": { textTransform: "uppercase" } } }, field, { disabled: true })));
                        } }), _jsx(Controller, { control: form.control, name: "fone1", render: function (_a) {
                            var field = _a.field, fieldState = _a.fieldState;
                            return (_jsx(TextField, { variant: "outlined", label: "Telefone", size: "small", error: !!fieldState.error, helperText: fieldState.error ? fieldState.error.message : " ", inputRef: phoneMask.ref, value: phoneMask.value, onChange: field.onChange, onBlur: field.onBlur, sx: { "& input": { textTransform: "uppercase" } }, disabled: disabled && !isRenewMode, inputProps: { form: { autocomplete: "off" } } }));
                        } })] })), _jsxs(Box, __assign({ sx: { display: "flex", flexDirection: "row", pt: 2 } }, { children: [_jsx(Button, __assign({ color: "primary", disabled: true, sx: { mr: 1 } }, { children: "Voltar" })), _jsx(Box, { sx: { flex: "1 1 auto" } }), _jsx(Button, __assign({ variant: "contained", type: "submit" }, { children: "Pr\u00F3ximo" }))] }))] })));
};
export default BeneficiaryForm;
