export var UFS = [
    "AC",
    "AL",
    "AM",
    "AP",
    "BA",
    "CE",
    "DF",
    "ES",
    "GO",
    "MA",
    "MG",
    "MS",
    "MT",
    "PA",
    "PB",
    "PE",
    "PI",
    "PR",
    "RJ",
    "RN",
    "RO",
    "RR",
    "RS",
    "SC",
    "SE",
    "SP",
    "TO",
    "",
];
export var GENDERS = ["M", "F", "O", ""];
export var GENDERS_OPTIONS = [
    { label: "MASCULINO", value: "M" },
    { label: "FEMININO", value: "F" },
    { label: "PREFIRO NÃO RESPONDER", value: "O" },
];
export var MAX_FILE_SIZE = 10000000;
export var MAX_ALL_FILE_SIZE = 50000000;
export var DEFAULT_ERROR_MESSAGE = "Não foi possível se comunicar com o servidor, tente novamente mais tarde.";
