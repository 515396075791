import Carteira from "../../pages/carteira";
import Home from "../../pages/home";
import Solicitacao from "../../pages/solicitacao";
import SolicitacaoEditar from "../../pages/solicitacao-editar";
import Renovacao from "../../pages/renovacao";
import RenovacaoEditar from "../../pages/renovacao-editar";
export var Routes = {
    "/renovacao/editar": RenovacaoEditar,
    "/renovacao": Renovacao,
    "/solicitacao/editar": SolicitacaoEditar,
    "/solicitacao": Solicitacao,
    "/carteira": Carteira,
    "/": Home,
};
