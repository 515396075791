export var slugify = function (value) {
    return value
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .trim()
        .replace(/[^a-z0-9 ]/g, "")
        .replace(/\s+/g, "-");
};
export var prettifyDocument = function (cpf) {
    return "".concat(cpf.substring(0, 3), ".").concat(cpf.substring(3, 6), ".").concat(cpf.substring(6, 9), "-").concat(cpf.substring(9, 11));
};
export var prettifyDocumentErrorMessage = function (message) {
    if (!message)
        return [];
    return message
        .replace("[", "")
        .replace("]", "")
        .split(", ")
        .map(function (document) { return "".concat(document, ";"); });
};
