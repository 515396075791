var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { useWebgate } from "@xvia/webgate-connect";
import { Link, useHistory } from "react-router-dom";
import { isAxiosError } from "axios";
import { useLoading } from "../../components/Loading";
import { getSolicitation, prepareCreateSolicitacaoPayload, resendSolicitation, } from "../../services/api/seid";
import { useFeedbackDialog } from "../../components/FeedbackDialog";
import { DEFAULT_ERROR_MESSAGE, GENDERS_OPTIONS } from "../../util/constants";
import BackButton from "../../components/BackButton";
import { Alert, AlertTitle, Paper, Typography } from "@mui/material";
import SolicitationStepper from "../../modules/form-steps/SolicitationStepper";
import { Situacao, } from "../../models";
import { parseDateToRead } from "../../util/date";
import { useStepperStore } from "../../stores/stepper";
import { prettifyDocumentErrorMessage } from "../../util/string";
import { useDocumentFieldsStore } from "../../stores/documentFields";
export default function SolicitacaoEditar() {
    var _this = this;
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x;
    var accessToken = useWebgate().accessToken;
    var history = useHistory();
    var loading = useLoading();
    var feedbackDialog = useFeedbackDialog();
    var resetAllData = useStepperStore(function (state) { return state.resetAllData; });
    var setActiveStep = useStepperStore(function (state) { return state.setActiveStep; });
    var setBeneficiaryData = useStepperStore(function (state) { return state.setBeneficiaryData; });
    var setAddressData = useStepperStore(function (state) { return state.setAddressData; });
    var setTypeSegments = useDocumentFieldsStore(function (state) { return state.setTypeSegments; });
    var _y = useState(null), solicitacao = _y[0], setSolicitacao = _y[1];
    var handleFormSubmit = useCallback(function (data) { return __awaiter(_this, void 0, void 0, function () {
        var preparedData, resendSolicitationResult, errorTitle, isDocumentErrorMessage;
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    loading.show();
                    preparedData = prepareCreateSolicitacaoPayload(data);
                    return [4 /*yield*/, resendSolicitation(accessToken, preparedData)];
                case 1:
                    resendSolicitationResult = _e.sent();
                    loading.hide();
                    if (isAxiosError(resendSolicitationResult)) {
                        errorTitle = "Aconteceu um erro!";
                        isDocumentErrorMessage = (_a = resendSolicitationResult.response) === null || _a === void 0 ? void 0 : _a.data.status.includes("DOCUMENTOS");
                        if (isDocumentErrorMessage) {
                            feedbackDialog.error({
                                title: errorTitle,
                                descriptions: __spreadArray([
                                    "Os seguintes documentos são obrigatórios:"
                                ], prettifyDocumentErrorMessage((_b = resendSolicitationResult.response) === null || _b === void 0 ? void 0 : _b.data.message), true),
                            });
                        }
                        else {
                            feedbackDialog.error({
                                title: errorTitle,
                                descriptions: [
                                    (_d = (_c = resendSolicitationResult.response) === null || _c === void 0 ? void 0 : _c.data.message) !== null && _d !== void 0 ? _d : DEFAULT_ERROR_MESSAGE,
                                ],
                                onCloseClick: function () { return window.history.back(); },
                            });
                        }
                    }
                    else {
                        feedbackDialog.success({
                            title: "Solicitação foi reenviada com sucesso",
                            descriptions: [
                                "Ao fechar você será redirecionado para a tela principal.",
                            ],
                            onCloseClick: function () {
                                resetAllData();
                                window.location.reload();
                            },
                        });
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [accessToken, feedbackDialog, loading, resetAllData]);
    useEffect(function () {
        var handleRedirect = function () { return __awaiter(_this, void 0, void 0, function () {
            var solicitationResult;
            var _a, _b, _c, _d, _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        if (!accessToken) return [3 /*break*/, 2];
                        loading.show();
                        return [4 /*yield*/, getSolicitation(accessToken)];
                    case 1:
                        solicitationResult = _f.sent();
                        loading.hide();
                        if (isAxiosError(solicitationResult)) {
                            if (((_a = solicitationResult.response) === null || _a === void 0 ? void 0 : _a.status) === 409) {
                                history.replace("/carteira");
                            }
                            if (((_b = solicitationResult.response) === null || _b === void 0 ? void 0 : _b.status) === 404) {
                                history.replace("/solicitacao");
                            }
                            else if (((_c = solicitationResult.response) === null || _c === void 0 ? void 0 : _c.status) >= 500) {
                                feedbackDialog.error({
                                    title: "Aconteceu um erro!",
                                    descriptions: [
                                        (_e = (_d = solicitationResult.response) === null || _d === void 0 ? void 0 : _d.data.message) !== null && _e !== void 0 ? _e : DEFAULT_ERROR_MESSAGE,
                                    ],
                                    onCloseClick: function () { return window.history.back(); },
                                });
                            }
                        }
                        else {
                            setSolicitacao(solicitationResult.data);
                            if (solicitationResult.data.situacao === Situacao.Pendente) {
                                setTypeSegments(solicitationResult.data.documentos
                                    .filter(function (document) { return Boolean(document.observacao); })
                                    .map(function (documento) { return documento.tipo.nome; }));
                            }
                        }
                        _f.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        handleRedirect();
    }, [accessToken, feedbackDialog, history, loading, setTypeSegments]);
    useEffect(function () {
        var _a, _b, _c, _d;
        if ((solicitacao === null || solicitacao === void 0 ? void 0 : solicitacao.situacao) === Situacao.Pendente) {
            setActiveStep(2);
            setBeneficiaryData({
                cpf: solicitacao.pessoa.cpf,
                dataNascimento: parseDateToRead(solicitacao.pessoa.dataNascimento),
                email: solicitacao.endereco.email,
                fone1: solicitacao.endereco.telefone,
                mae: solicitacao.pessoa.mae,
                naturalidadeUf: (_a = solicitacao === null || solicitacao === void 0 ? void 0 : solicitacao.pessoa) === null || _a === void 0 ? void 0 : _a.naturalidade.uf,
                naturalidade: "".concat((_b = solicitacao === null || solicitacao === void 0 ? void 0 : solicitacao.pessoa) === null || _b === void 0 ? void 0 : _b.naturalidade.id),
                nacionalidade: "".concat((_c = solicitacao === null || solicitacao === void 0 ? void 0 : solicitacao.pessoa) === null || _c === void 0 ? void 0 : _c.nacionalidade.id),
                nome: solicitacao.pessoa.nome,
                rg: solicitacao.pessoa.rg,
                rgDataExpedicao: parseDateToRead(solicitacao.pessoa.rgDataExpedicao),
                rgOrgaoExpedidor: solicitacao.pessoa.rgOrgaoExpedidor,
                rgUf: solicitacao.pessoa.rgUf,
                sexo: ((_d = GENDERS_OPTIONS.find(function (genderOption) { var _a; return genderOption.label === ((_a = solicitacao === null || solicitacao === void 0 ? void 0 : solicitacao.pessoa) === null || _a === void 0 ? void 0 : _a.sexo); })) === null || _d === void 0 ? void 0 : _d.value) || "",
                pai: solicitacao.pessoa.pai || undefined,
            });
            setAddressData({
                bairro: solicitacao.endereco.bairro,
                cep: solicitacao.endereco.cep,
                cidade: solicitacao.endereco.cidade,
                logradouro: solicitacao.endereco.logradouro,
                numero: "".concat(solicitacao.endereco.numero),
                uf: solicitacao.endereco.uf,
                complemento: solicitacao.endereco.complemento,
            });
        }
    }, [setActiveStep, setAddressData, setBeneficiaryData, solicitacao]);
    return (_jsxs(_Fragment, { children: [_jsx(BackButton, {}), _jsxs(Paper, __assign({ sx: function (theme) { return ({ my: theme.spacing(2), p: theme.spacing(2) }); } }, { children: [(solicitacao === null || solicitacao === void 0 ? void 0 : solicitacao.situacao) === Situacao.Deferido && (_jsxs(Alert, __assign({ severity: "success", sx: { mb: 2 } }, { children: ["Solicita\u00E7\u00E3o deferida \u2014", " ", _jsx(Link, __assign({ to: "/" }, { children: _jsx("strong", { children: "clique aqui para acessar sua carteira" }) })), "."] }))), (solicitacao === null || solicitacao === void 0 ? void 0 : solicitacao.situacao) === Situacao.EmAnalise && (_jsx(Alert, __assign({ severity: "info", sx: { mb: 2 } }, { children: "Solicita\u00E7\u00E3o em an\u00E1lise" }))), (solicitacao === null || solicitacao === void 0 ? void 0 : solicitacao.situacao) === Situacao.Pendente && (_jsxs(Alert, __assign({ severity: "warning", sx: { mb: 2 } }, { children: ["Solicita\u00E7\u00E3o pedente de mudan\u00E7as, motivo: ", solicitacao === null || solicitacao === void 0 ? void 0 : solicitacao.observacao] }))), (solicitacao === null || solicitacao === void 0 ? void 0 : solicitacao.situacao) === Situacao.Indeferido && (_jsxs(Alert, __assign({ severity: "error", sx: { mb: 2 } }, { children: [_jsx(AlertTitle, { children: "Solicita\u00E7\u00E3o indeferida" }), _jsx("p", { children: solicitacao === null || solicitacao === void 0 ? void 0 : solicitacao.observacao }), _jsx("p", { children: "Por favor entre contato para maiores informa\u00E7\u00F5es ou para recorrer decis\u00E3o da sua solicita\u00E7\u00E3o." }), _jsx("p", { children: "Secretaria de Estado para Inclus\u00E3o da Pessoa com Defici\u00EAncia" }), _jsx("p", { children: "Rua \u00C1lvaro Mendes, 1432 - Bairro Centro/Norte - CEP 64000-060 - Teresina-PI" }), _jsx("p", { children: "Telefone +55 86 99493-8859" })] }))), _jsx(Typography, __assign({ component: "h1", sx: function (theme) { return ({
                            mb: theme.spacing(2),
                            fontWeight: 700,
                            fontSize: "1.5rem",
                            color: theme.palette.primary.dark,
                        }); } }, { children: "Solicita\u00E7\u00E3o - Carteira de Identifica\u00E7\u00E3o do Autista" })), _jsx(SolicitationStepper, { disableSubmit: (solicitacao === null || solicitacao === void 0 ? void 0 : solicitacao.situacao) !== Situacao.Pendente, mode: "edit", id: "".concat(solicitacao === null || solicitacao === void 0 ? void 0 : solicitacao.id), token: accessToken, onFormSubmit: handleFormSubmit, documents: solicitacao === null || solicitacao === void 0 ? void 0 : solicitacao.documentos, defaultValues: {
                            beneficiary: {
                                nome: ((_a = solicitacao === null || solicitacao === void 0 ? void 0 : solicitacao.pessoa) === null || _a === void 0 ? void 0 : _a.nome) || "",
                                cpf: ((_b = solicitacao === null || solicitacao === void 0 ? void 0 : solicitacao.pessoa) === null || _b === void 0 ? void 0 : _b.cpf) || "",
                                rg: ((_c = solicitacao === null || solicitacao === void 0 ? void 0 : solicitacao.pessoa) === null || _c === void 0 ? void 0 : _c.rg) || "",
                                rgOrgaoExpedidor: ((_d = solicitacao === null || solicitacao === void 0 ? void 0 : solicitacao.pessoa) === null || _d === void 0 ? void 0 : _d.rgOrgaoExpedidor) || "",
                                rgDataExpedicao: parseDateToRead((_e = solicitacao === null || solicitacao === void 0 ? void 0 : solicitacao.pessoa) === null || _e === void 0 ? void 0 : _e.rgDataExpedicao),
                                rgUf: ((_f = solicitacao === null || solicitacao === void 0 ? void 0 : solicitacao.pessoa) === null || _f === void 0 ? void 0 : _f.rgUf) || "",
                                dataNascimento: parseDateToRead((_g = solicitacao === null || solicitacao === void 0 ? void 0 : solicitacao.pessoa) === null || _g === void 0 ? void 0 : _g.dataNascimento),
                                sexo: ((_h = GENDERS_OPTIONS.find(function (genderOption) { var _a; return genderOption.label === ((_a = solicitacao === null || solicitacao === void 0 ? void 0 : solicitacao.pessoa) === null || _a === void 0 ? void 0 : _a.sexo); })) === null || _h === void 0 ? void 0 : _h.value) || "",
                                naturalidadeUf: ((_j = solicitacao === null || solicitacao === void 0 ? void 0 : solicitacao.pessoa) === null || _j === void 0 ? void 0 : _j.naturalidade.uf) || "",
                                naturalidade: "".concat((_k = solicitacao === null || solicitacao === void 0 ? void 0 : solicitacao.pessoa) === null || _k === void 0 ? void 0 : _k.naturalidade.id) || "",
                                nacionalidade: "".concat((_l = solicitacao === null || solicitacao === void 0 ? void 0 : solicitacao.pessoa) === null || _l === void 0 ? void 0 : _l.nacionalidade.id),
                                mae: ((_m = solicitacao === null || solicitacao === void 0 ? void 0 : solicitacao.pessoa) === null || _m === void 0 ? void 0 : _m.mae) || "",
                                pai: ((_o = solicitacao === null || solicitacao === void 0 ? void 0 : solicitacao.pessoa) === null || _o === void 0 ? void 0 : _o.pai) || "",
                                email: ((_p = solicitacao === null || solicitacao === void 0 ? void 0 : solicitacao.endereco) === null || _p === void 0 ? void 0 : _p.email) || "",
                                fone1: ((_q = solicitacao === null || solicitacao === void 0 ? void 0 : solicitacao.endereco) === null || _q === void 0 ? void 0 : _q.telefone) || "",
                            },
                            address: {
                                cep: "".concat((_r = solicitacao === null || solicitacao === void 0 ? void 0 : solicitacao.endereco) === null || _r === void 0 ? void 0 : _r.cep) || "",
                                logradouro: ((_s = solicitacao === null || solicitacao === void 0 ? void 0 : solicitacao.endereco) === null || _s === void 0 ? void 0 : _s.logradouro) || "",
                                bairro: ((_t = solicitacao === null || solicitacao === void 0 ? void 0 : solicitacao.endereco) === null || _t === void 0 ? void 0 : _t.bairro) || "",
                                numero: "".concat((_u = solicitacao === null || solicitacao === void 0 ? void 0 : solicitacao.endereco) === null || _u === void 0 ? void 0 : _u.numero) || "",
                                complemento: ((_v = solicitacao === null || solicitacao === void 0 ? void 0 : solicitacao.endereco) === null || _v === void 0 ? void 0 : _v.complemento) || "",
                                uf: ((_w = solicitacao === null || solicitacao === void 0 ? void 0 : solicitacao.endereco) === null || _w === void 0 ? void 0 : _w.uf) || "",
                                cidade: ((_x = solicitacao === null || solicitacao === void 0 ? void 0 : solicitacao.endereco) === null || _x === void 0 ? void 0 : _x.cidade) || "",
                            },
                            documents: {
                                termoDeResponsabilidade: undefined,
                            },
                        } })] }))] }));
}
