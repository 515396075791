import * as z from "zod";
import { UFS, GENDERS } from "../util/constants";
import { emptySelectRefinementCheck, requiredFieldMessage, zodStringOptionalOrEmpty, } from "../util/validation";
export var beneficiaryFormSchema = z
    .object({
    nome: z.string().min(1, requiredFieldMessage),
    cpf: z.string().min(1, requiredFieldMessage),
    rgOrgaoExpedidor: z
        .string()
        .min(1, requiredFieldMessage)
        .max(3, "No máximo 3 caracteres"),
    rgUf: z
        .enum(UFS, { errorMap: function () { return ({ message: requiredFieldMessage }); } })
        .or(z.string())
        .refine(emptySelectRefinementCheck, { message: requiredFieldMessage }),
    rgDataExpedicao: z.coerce
        .date({
        errorMap: function () { return ({ message: requiredFieldMessage }); },
    })
        .refine(function (data) { return data < new Date(); }, { message: "Data inválida" }),
    rg: z
        .string()
        .min(1, requiredFieldMessage)
        .max(14, "No máximo 14 caracteres"),
    dataNascimento: z.coerce
        .date({
        errorMap: function () { return ({ message: requiredFieldMessage }); },
    })
        .refine(function (data) { return data < new Date(); }, { message: "Data inválida" }),
    sexo: z
        .enum(GENDERS, { errorMap: function () { return ({ message: requiredFieldMessage }); } })
        .or(z.string())
        .refine(emptySelectRefinementCheck, { message: requiredFieldMessage }),
    naturalidadeUf: z
        .enum(UFS, { errorMap: function () { return ({ message: requiredFieldMessage }); } })
        .or(z.string())
        .refine(emptySelectRefinementCheck, { message: requiredFieldMessage }),
    naturalidade: z.string().min(1, requiredFieldMessage),
    nacionalidade: z.string().min(1, requiredFieldMessage),
    mae: z.string().min(1, requiredFieldMessage),
    pai: zodStringOptionalOrEmpty,
    email: z
        .string()
        .min(1, requiredFieldMessage)
        .email({ message: "Formato de e-mail inválido" }),
    fone1: z
        .string()
        .min(1, { message: requiredFieldMessage })
        .refine(function (value) {
        var phoneValueField = value
            .split(" ")
            .join("")
            .replace("(", "")
            .replace(")", "")
            .replace("-", "");
        return phoneValueField.length === 11;
    }, { message: "Número de telefone inválido" }),
})
    .refine(function (values) { return values.rgDataExpedicao > values.dataNascimento; }, {
    message: "A data de expedição do RG não pode ser menor que a data de nascimento",
    path: ["rgDataExpedicao"],
});
