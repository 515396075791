var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
var ResponsiveIndicator = function () {
    if (process.env.NODE_ENV === "production")
        return null;
    return (_jsxs(Box, __assign({ sx: function (theme) { return ({
            position: "fixed",
            bottom: theme.spacing(1),
            left: theme.spacing(1),
            height: theme.spacing(3),
            px: 1,
            width: "fit-content",
            z: 500,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 9999,
            backgroundColor: "rgba(0, 0, 0, 0.6)",
        }); } }, { children: [_jsx(Typography, __assign({ component: "span", sx: {
                    color: "white",
                    fontSize: "0.75rem",
                    lineHeight: 1,
                    fontFamily: "monospace",
                    fontWeight: 400,
                    display: {
                        xs: "block",
                        sm: "none",
                        md: "none",
                        lg: "none",
                        xl: "none",
                    },
                } }, { children: "xs" })), _jsx(Typography, __assign({ component: "span", sx: {
                    color: "white",
                    fontSize: "0.75rem",
                    lineHeight: 1,
                    fontFamily: "monospace",
                    fontWeight: 400,
                    display: {
                        xs: "none",
                        sm: "block",
                        md: "none",
                        lg: "none",
                        xl: "none",
                    },
                } }, { children: "sm" })), _jsx(Typography, __assign({ component: "span", sx: {
                    color: "white",
                    fontSize: "0.75rem",
                    lineHeight: 1,
                    fontFamily: "monospace",
                    fontWeight: 400,
                    display: {
                        xs: "none",
                        sm: "none",
                        md: "block",
                        lg: "none",
                        xl: "none",
                    },
                } }, { children: "md" })), _jsx(Typography, __assign({ component: "span", sx: {
                    color: "white",
                    fontSize: "0.75rem",
                    lineHeight: 1,
                    fontFamily: "monospace",
                    fontWeight: 400,
                    display: {
                        xs: "none",
                        sm: "none",
                        md: "none",
                        lg: "block",
                        xl: "none",
                    },
                } }, { children: "lg" })), _jsx(Typography, __assign({ component: "span", sx: {
                    color: "white",
                    fontSize: "0.75rem",
                    lineHeight: 1,
                    fontFamily: "monospace",
                    fontWeight: 400,
                    display: {
                        xs: "none",
                        sm: "none",
                        md: "none",
                        lg: "none",
                        xl: "block",
                    },
                } }, { children: "xl" }))] })));
};
export default ResponsiveIndicator;
