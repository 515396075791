var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog, DialogContent, CircularProgress, Stack, Typography, } from "@mui/material";
import { useCallback, useMemo } from "react";
import { create } from "zustand";
var useLoadingStore = create(function (set, get) { return ({
    isLoading: false,
    loadingText: "Carregando...",
    setIsLoading: function (isLoading) { return set({ isLoading: isLoading }); },
    setLoadingText: function (loadingText) { return set({ loadingText: loadingText }); },
    showLoading: function (payload) {
        return set(__assign({ isLoading: true }, (payload && { loadingText: payload })));
    },
    hideLoading: function () { return set({ isLoading: false }); },
}); });
export var useLoading = function () {
    var showLoading = useLoadingStore(function (state) { return state.showLoading; });
    var hideLoading = useLoadingStore(function (state) { return state.hideLoading; });
    var setIsLoading = useLoadingStore(function (state) { return state.setIsLoading; });
    var result = useMemo(function () { return ({
        show: function (message) {
            if (message === void 0) { message = "Carregando..."; }
            return showLoading(message);
        },
        hide: function () { return hideLoading(); },
        set: function (isLoading) { return setIsLoading(isLoading); },
    }); }, [hideLoading, setIsLoading, showLoading]);
    return result;
};
export default function Loading() {
    var isLoading = useLoadingStore(function (state) { return state.isLoading; });
    var loadingText = useLoadingStore(function (state) { return state.loadingText; });
    var hideLoading = useLoadingStore(function (state) { return state.hideLoading; });
    var handleClose = useCallback(function (_event, reason) {
        if (reason === "backdropClick" || reason === "escapeKeyDown")
            return;
        hideLoading();
    }, [hideLoading]);
    return (_jsx(Dialog, __assign({ onClose: handleClose, open: isLoading, PaperProps: {
            sx: {
                width: "fit-content",
                maxWidth: "400px",
                padding: "20px",
                backgroundColor: "#fff",
                borderRadius: "4px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            },
        } }, { children: _jsx(DialogContent, __assign({ sx: { p: 0 } }, { children: _jsxs(Stack, __assign({ spacing: 2, sx: { alignItems: "center", justifyContent: "center" } }, { children: [_jsx(CircularProgress, {}), _jsx(Typography, __assign({ sx: {
                            fontSize: ".875rem",
                            fontWeight: 500,
                            color: "#333",
                            textAlign: "center",
                        } }, { children: loadingText }))] })) })) })));
}
