var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from "react";
import { useWebgate } from "@xvia/webgate-connect";
import { useHistory } from "react-router-dom";
import { isAxiosError } from "axios";
import { useLoading } from "../../components/Loading";
import { createRenew, getCIACard, getPerson, getRenew, prepareCreateRenovacaoPayload, } from "../../services/api/seid";
import { useFeedbackDialog } from "../../components/FeedbackDialog";
import { DEFAULT_ERROR_MESSAGE, GENDERS_OPTIONS } from "../../util/constants";
import BackButton from "../../components/BackButton";
import { Paper, Typography } from "@mui/material";
import SolicitationStepper from "../../modules/form-steps/SolicitationStepper";
import { Situacao, } from "../../models";
import { parseDateToRead } from "../../util/date";
import { useStepperStore } from "../../stores/stepper";
import { prettifyDocumentErrorMessage } from "../../util/string";
export default function SolicitacaoEditar() {
    var _this = this;
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x;
    var _y = useWebgate(), userInfo = _y.userInfo, accessToken = _y.accessToken;
    var history = useHistory();
    var loading = useLoading();
    var feedbackDialog = useFeedbackDialog();
    var resetAllData = useStepperStore(function (state) { return state.resetAllData; });
    var _z = useState(null), pessoa = _z[0], setPessoa = _z[1];
    var handleFormSubmit = useCallback(function (data) { return __awaiter(_this, void 0, void 0, function () {
        var preparedData, createRenewResult, errorTitle, isDocumentErrorMessage;
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    loading.show();
                    preparedData = prepareCreateRenovacaoPayload({
                        endereco: {
                            bairro: data.endereco.bairro,
                            cep: data.endereco.cep,
                            cidade: data.endereco.cidade,
                            complemento: data.endereco.complemento,
                            email: data.endereco.email,
                            logradouro: data.endereco.logradouro,
                            numero: "".concat(data.endereco.numero),
                            telefone: data.endereco.telefone,
                            uf: data.endereco.uf,
                        },
                        arquivos: data.arquivos,
                    });
                    return [4 /*yield*/, createRenew(accessToken, preparedData)];
                case 1:
                    createRenewResult = _e.sent();
                    loading.hide();
                    if (isAxiosError(createRenewResult)) {
                        errorTitle = "Aconteceu um erro!";
                        isDocumentErrorMessage = (_a = createRenewResult.response) === null || _a === void 0 ? void 0 : _a.data.status.includes("DOCUMENTOS");
                        if (isDocumentErrorMessage) {
                            feedbackDialog.error({
                                title: errorTitle,
                                descriptions: __spreadArray([
                                    "Os seguintes documentos são obrigatórios:"
                                ], prettifyDocumentErrorMessage((_b = createRenewResult.response) === null || _b === void 0 ? void 0 : _b.data.message), true),
                            });
                        }
                        else {
                            feedbackDialog.error({
                                title: errorTitle,
                                descriptions: [
                                    (_d = (_c = createRenewResult.response) === null || _c === void 0 ? void 0 : _c.data.message) !== null && _d !== void 0 ? _d : DEFAULT_ERROR_MESSAGE,
                                ],
                                onCloseClick: function () { return window.history.back(); },
                            });
                        }
                    }
                    else {
                        feedbackDialog.success({
                            title: "Solicitação de renovação foi reenviada com sucesso",
                            descriptions: [
                                "Ao fechar você será redirecionado para a tela principal.",
                            ],
                            onCloseClick: function () {
                                resetAllData();
                                window.location.reload();
                            },
                        });
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [accessToken, feedbackDialog, loading, resetAllData]);
    useEffect(function () {
        var handleRedirect = function () { return __awaiter(_this, void 0, void 0, function () {
            var personResult, renewSolicitationResult, ciaCardResult;
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
            return __generator(this, function (_l) {
                switch (_l.label) {
                    case 0:
                        if (!accessToken) return [3 /*break*/, 4];
                        loading.show();
                        return [4 /*yield*/, getPerson(accessToken)];
                    case 1:
                        personResult = _l.sent();
                        return [4 /*yield*/, getRenew(accessToken)];
                    case 2:
                        renewSolicitationResult = _l.sent();
                        return [4 /*yield*/, getCIACard(accessToken)];
                    case 3:
                        ciaCardResult = _l.sent();
                        loading.hide();
                        if (isAxiosError(personResult)) {
                            if (((_a = personResult.response) === null || _a === void 0 ? void 0 : _a.status) >= 500) {
                                feedbackDialog.error({
                                    title: "Aconteceu um erro!",
                                    descriptions: [
                                        (_c = (_b = personResult.response) === null || _b === void 0 ? void 0 : _b.data.message) !== null && _c !== void 0 ? _c : DEFAULT_ERROR_MESSAGE,
                                    ],
                                    onCloseClick: function () { return window.history.back(); },
                                });
                            }
                        }
                        else {
                            setPessoa(personResult.data);
                        }
                        if (isAxiosError(ciaCardResult)) {
                            if (((_d = ciaCardResult.response) === null || _d === void 0 ? void 0 : _d.status) === 404) {
                                history.replace("/solicitacao");
                            }
                            else {
                                feedbackDialog.error({
                                    title: "Aconteceu um erro!",
                                    descriptions: [
                                        (_f = (_e = ciaCardResult.response) === null || _e === void 0 ? void 0 : _e.data.message) !== null && _f !== void 0 ? _f : DEFAULT_ERROR_MESSAGE,
                                    ],
                                    onCloseClick: function () { return window.history.back(); },
                                });
                            }
                        }
                        else if (ciaCardResult.data.valida) {
                            history.replace("/carteira");
                        }
                        if (isAxiosError(renewSolicitationResult)) {
                            if (((_g = renewSolicitationResult.response) === null || _g === void 0 ? void 0 : _g.status) === 409) {
                                history.replace("/carteira");
                            }
                            else if (((_h = renewSolicitationResult.response) === null || _h === void 0 ? void 0 : _h.status) >= 500) {
                                feedbackDialog.error({
                                    title: "Aconteceu um erro!",
                                    descriptions: [
                                        (_k = (_j = renewSolicitationResult.response) === null || _j === void 0 ? void 0 : _j.data.message) !== null && _k !== void 0 ? _k : DEFAULT_ERROR_MESSAGE,
                                    ],
                                    onCloseClick: function () { return window.history.back(); },
                                });
                            }
                        }
                        else if (renewSolicitationResult.data.situacao === Situacao.EmAnalise ||
                            renewSolicitationResult.data.situacao === Situacao.Pendente ||
                            renewSolicitationResult.data.situacao === Situacao.Indeferido) {
                            history.replace("/renovacao/editar");
                        }
                        _l.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        handleRedirect();
    }, [accessToken, feedbackDialog, history, loading]);
    return (_jsxs(_Fragment, { children: [_jsx(BackButton, {}), _jsxs(Paper, __assign({ sx: function (theme) { return ({ my: theme.spacing(2), p: theme.spacing(2) }); } }, { children: [_jsx(Typography, __assign({ component: "h1", sx: function (theme) { return ({
                            fontWeight: 700,
                            fontSize: "1.5rem",
                            color: theme.palette.primary.dark,
                        }); } }, { children: "Solicita\u00E7\u00E3o de renova\u00E7\u00E3o - Carteira de Identifica\u00E7\u00E3o do Autista" })), _jsx(Typography, __assign({ sx: { mt: 1, mb: 2, color: "rgba(0, 0, 0, 0.6)" } }, { children: "Para solicitar a renova\u00E7\u00E3o da carteira de identifica\u00E7\u00E3o do autista, preencha corretamente os campos que n\u00E3o est\u00E3o desabilitados assim como os documentos atualizados." })), _jsx(SolicitationStepper, { mode: "renew", token: accessToken, onFormSubmit: handleFormSubmit, defaultValues: {
                            beneficiary: {
                                nome: ((_a = userInfo === null || userInfo === void 0 ? void 0 : userInfo.name) === null || _a === void 0 ? void 0 : _a.trim()) || "",
                                cpf: ((_b = userInfo === null || userInfo === void 0 ? void 0 : userInfo.preferred_username) === null || _b === void 0 ? void 0 : _b.trim()) || "",
                                rg: ((_c = pessoa === null || pessoa === void 0 ? void 0 : pessoa.pessoa) === null || _c === void 0 ? void 0 : _c.rg) || "",
                                rgOrgaoExpedidor: ((_d = pessoa === null || pessoa === void 0 ? void 0 : pessoa.pessoa) === null || _d === void 0 ? void 0 : _d.rgOrgaoExpedidor) || "",
                                rgDataExpedicao: parseDateToRead((_e = pessoa === null || pessoa === void 0 ? void 0 : pessoa.pessoa) === null || _e === void 0 ? void 0 : _e.rgDataExpedicao),
                                rgUf: ((_f = pessoa === null || pessoa === void 0 ? void 0 : pessoa.pessoa) === null || _f === void 0 ? void 0 : _f.rgUf) || "",
                                dataNascimento: parseDateToRead((_g = pessoa === null || pessoa === void 0 ? void 0 : pessoa.pessoa) === null || _g === void 0 ? void 0 : _g.dataNascimento),
                                sexo: ((_h = GENDERS_OPTIONS.find(function (genderOption) { var _a; return genderOption.label === ((_a = pessoa === null || pessoa === void 0 ? void 0 : pessoa.pessoa) === null || _a === void 0 ? void 0 : _a.sexo); })) === null || _h === void 0 ? void 0 : _h.value) || "",
                                naturalidadeUf: ((_j = pessoa === null || pessoa === void 0 ? void 0 : pessoa.pessoa) === null || _j === void 0 ? void 0 : _j.naturalidade.uf) || "",
                                naturalidade: "".concat((_k = pessoa === null || pessoa === void 0 ? void 0 : pessoa.pessoa) === null || _k === void 0 ? void 0 : _k.naturalidade.id) || "",
                                nacionalidade: "".concat((_l = pessoa === null || pessoa === void 0 ? void 0 : pessoa.pessoa) === null || _l === void 0 ? void 0 : _l.nacionalidade.id),
                                mae: ((_m = pessoa === null || pessoa === void 0 ? void 0 : pessoa.pessoa) === null || _m === void 0 ? void 0 : _m.mae) || "",
                                pai: ((_o = pessoa === null || pessoa === void 0 ? void 0 : pessoa.pessoa) === null || _o === void 0 ? void 0 : _o.pai) || "",
                                email: ((_p = userInfo === null || userInfo === void 0 ? void 0 : userInfo.email) === null || _p === void 0 ? void 0 : _p.trim()) || "",
                                fone1: ((_q = pessoa === null || pessoa === void 0 ? void 0 : pessoa.endereco) === null || _q === void 0 ? void 0 : _q.telefone) || "",
                            },
                            address: {
                                cep: "".concat((_r = pessoa === null || pessoa === void 0 ? void 0 : pessoa.endereco) === null || _r === void 0 ? void 0 : _r.cep) || "",
                                logradouro: ((_s = pessoa === null || pessoa === void 0 ? void 0 : pessoa.endereco) === null || _s === void 0 ? void 0 : _s.logradouro) || "",
                                bairro: ((_t = pessoa === null || pessoa === void 0 ? void 0 : pessoa.endereco) === null || _t === void 0 ? void 0 : _t.bairro) || "",
                                numero: "".concat((_u = pessoa === null || pessoa === void 0 ? void 0 : pessoa.endereco) === null || _u === void 0 ? void 0 : _u.numero) || "",
                                complemento: ((_v = pessoa === null || pessoa === void 0 ? void 0 : pessoa.endereco) === null || _v === void 0 ? void 0 : _v.complemento) || "",
                                uf: ((_w = pessoa === null || pessoa === void 0 ? void 0 : pessoa.endereco) === null || _w === void 0 ? void 0 : _w.uf) || "",
                                cidade: ((_x = pessoa === null || pessoa === void 0 ? void 0 : pessoa.endereco) === null || _x === void 0 ? void 0 : _x.cidade) || "",
                            },
                            documents: {
                                termoDeResponsabilidade: undefined,
                            },
                        } })] }))] }));
}
