var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, MenuItem, Stack, TextField } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useIMask } from "react-imask";
import { UFS } from "../../util/constants";
import { getAddressFromCep } from "../../apis/cep";
import { addressFormSchema } from "../../forms/address";
import { zodResolver } from "@hookform/resolvers/zod";
import { useStepperStore } from "../../stores/stepper";
import { useDocumentFieldsStore } from "../../stores/documentFields";
var AddressForm = function (_a) {
    var disabled = _a.disabled, defaultValues = _a.defaultValues, mode = _a.mode;
    var addressData = useStepperStore(function (state) { return state.addressData; });
    var setAddressData = useStepperStore(function (state) { return state.setAddressData; });
    var goToNextStep = useStepperStore(function (state) { return state.goToNextStep; });
    var goToPreviousStep = useStepperStore(function (state) { return state.goToPreviousStep; });
    var setIsActiveStepValid = useStepperStore(function (state) { return state.setIsActiveStepValid; });
    var enableAddressProofFields = useDocumentFieldsStore(function (state) { return state.enableAddressProofFields; });
    var _b = useState(false), isAddressFromCep = _b[0], setIsAddressFromCep = _b[1];
    var isEditMode = useMemo(function () { return mode === "edit"; }, [mode]);
    var cepMask = useIMask({ mask: "00000-000" });
    var addressNumberMask = useIMask({ mask: /^\d+$/ });
    var form = useForm({
        defaultValues: addressData !== null && addressData !== void 0 ? addressData : defaultValues,
        resolver: zodResolver(addressFormSchema),
    });
    var cepValue = form.watch("cep");
    useEffect(function () {
        if (addressData) {
            if ((addressData === null || addressData === void 0 ? void 0 : addressData.cep) && cepMask.typedValue === "")
                cepMask.setUnmaskedValue(addressData === null || addressData === void 0 ? void 0 : addressData.cep);
            if ((addressData === null || addressData === void 0 ? void 0 : addressData.numero) && addressNumberMask.typedValue === "")
                addressNumberMask.setUnmaskedValue(addressData === null || addressData === void 0 ? void 0 : addressData.numero);
        }
    }, [addressData, cepMask, addressNumberMask]);
    useEffect(function () {
        var getAddress = function () { return __awaiter(void 0, void 0, void 0, function () {
            var address;
            var _a, _b, _c, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0: return [4 /*yield*/, getAddressFromCep(cepValue)];
                    case 1:
                        address = _e.sent();
                        form.setValue("logradouro", (_a = address === null || address === void 0 ? void 0 : address.logradouro) === null || _a === void 0 ? void 0 : _a.toUpperCase(), {
                            shouldTouch: true,
                            shouldValidate: true,
                        });
                        form.setValue("bairro", (_b = address === null || address === void 0 ? void 0 : address.bairro) === null || _b === void 0 ? void 0 : _b.toUpperCase(), {
                            shouldTouch: true,
                            shouldValidate: true,
                        });
                        form.setValue("cidade", (_c = address === null || address === void 0 ? void 0 : address.localidade) === null || _c === void 0 ? void 0 : _c.toUpperCase(), {
                            shouldTouch: true,
                            shouldValidate: true,
                        });
                        form.setValue("uf", (_d = address === null || address === void 0 ? void 0 : address.uf) === null || _d === void 0 ? void 0 : _d.toUpperCase(), {
                            shouldTouch: true,
                            shouldValidate: true,
                        });
                        if (address.localidade && address.uf) {
                            setIsAddressFromCep(true);
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        if (cepValue && cepValue.length >= 9) {
            getAddress();
        }
        if (!cepValue || (cepValue === null || cepValue === void 0 ? void 0 : cepValue.trim()) === "") {
            form.resetField("logradouro");
            form.resetField("bairro");
            form.resetField("cidade");
            form.resetField("uf");
            setIsAddressFromCep(false);
        }
    }, [cepValue, form]);
    useEffect(function () {
        setIsActiveStepValid(form.formState.isSubmitted && !form.formState.isValid);
    }, [form.formState, setIsActiveStepValid]);
    useEffect(function () {
        if ((defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.cep) && cepMask.ref.current)
            cepMask.setUnmaskedValue(defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.cep);
    }, [defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.cep, cepMask.ref.current]);
    useEffect(function () {
        if ((defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.numero) && addressNumberMask.ref.current)
            addressNumberMask.setUnmaskedValue(defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.numero);
    }, [defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.numero, addressNumberMask.ref.current]);
    useEffect(function () {
        if (addressData) {
            if (addressData === null || addressData === void 0 ? void 0 : addressData.cep)
                cepMask.setUnmaskedValue(addressData === null || addressData === void 0 ? void 0 : addressData.cep);
            if (addressData === null || addressData === void 0 ? void 0 : addressData.numero)
                addressNumberMask.setUnmaskedValue(addressData === null || addressData === void 0 ? void 0 : addressData.numero);
        }
    }, [addressData]);
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setAddressData({
                bairro: data.bairro,
                cep: cepMask.unmaskedValue,
                cidade: data.cidade,
                logradouro: data.logradouro,
                numero: addressNumberMask.unmaskedValue,
                uf: data.uf,
                complemento: data.complemento,
            });
            goToNextStep();
            setIsActiveStepValid(true);
            return [2 /*return*/];
        });
    }); };
    return (_jsxs("form", __assign({ onSubmit: form.handleSubmit(onSubmit), autoComplete: "off" }, { children: [_jsxs(Stack, __assign({ spacing: 2 }, { children: [_jsx(Controller, { control: form.control, name: "cep", render: function (_a) {
                            var field = _a.field, fieldState = _a.fieldState;
                            return (_jsx(TextField, { variant: "outlined", label: "CEP", size: "small", error: !!fieldState.error, helperText: fieldState.error ? fieldState.error.message : " ", inputRef: cepMask.ref, value: cepMask.value, onChange: field.onChange, onBlur: field.onBlur, disabled: isEditMode ? disabled && !enableAddressProofFields : disabled, sx: { "& input": { textTransform: "uppercase" } }, inputProps: { form: { autocomplete: "off" } } }));
                        } }), _jsx(Controller, { control: form.control, name: "logradouro", render: function (_a) {
                            var field = _a.field, fieldState = _a.fieldState;
                            return (_jsx(TextField, __assign({ variant: "outlined", label: "Endere\u00E7o", size: "small", error: !!fieldState.error, helperText: fieldState.error ? fieldState.error.message : " ", sx: { "& input": { textTransform: "uppercase" } } }, field, { disabled: isEditMode ? disabled && !enableAddressProofFields : disabled, inputProps: { maxLength: 50 } })));
                        } }), _jsx(Controller, { control: form.control, name: "numero", render: function (_a) {
                            var field = _a.field, fieldState = _a.fieldState;
                            return (_jsx(TextField, { variant: "outlined", label: "N\u00FAmero", size: "small", error: !!fieldState.error, helperText: fieldState.error ? fieldState.error.message : " ", inputRef: addressNumberMask.ref, value: addressNumberMask.value, onChange: field.onChange, onBlur: field.onBlur, sx: { "& input": { textTransform: "uppercase" } }, disabled: isEditMode ? disabled && !enableAddressProofFields : disabled, inputProps: { form: { autocomplete: "off" }, maxLength: 10 } }));
                        } }), _jsx(Controller, { control: form.control, name: "bairro", render: function (_a) {
                            var field = _a.field, fieldState = _a.fieldState;
                            return (_jsx(TextField, __assign({ variant: "outlined", label: "Bairro", size: "small", error: !!fieldState.error, helperText: fieldState.error ? fieldState.error.message : " ", sx: { "& input": { textTransform: "uppercase" } } }, field, { disabled: isEditMode ? disabled && !enableAddressProofFields : disabled, inputProps: { maxLength: 150 } })));
                        } }), _jsx(Controller, { control: form.control, name: "complemento", render: function (_a) {
                            var field = _a.field;
                            return (_jsx(TextField, __assign({ variant: "outlined", label: "Complemento", size: "small", helperText: " ", sx: { "& input": { textTransform: "uppercase" } } }, field, { disabled: isEditMode ? disabled && !enableAddressProofFields : disabled, inputProps: { maxLength: 50 } })));
                        } }), _jsx(Controller, { control: form.control, name: "uf", render: function (_a) {
                            var field = _a.field, fieldState = _a.fieldState;
                            return (_jsx(TextField, __assign({ select: true, variant: "outlined", label: "UF", size: "small", error: !!fieldState.error, helperText: fieldState.error ? fieldState.error.message : " " }, field, { disabled: isEditMode
                                    ? (disabled || isAddressFromCep) && !enableAddressProofFields
                                    : disabled || isAddressFromCep }, { children: UFS.map(function (uf) { return (_jsx(MenuItem, __assign({ value: uf }, { children: uf }), uf)); }) })));
                        } }), _jsx(Controller, { control: form.control, name: "cidade", render: function (_a) {
                            var field = _a.field, fieldState = _a.fieldState;
                            return (_jsx(TextField, __assign({ variant: "outlined", label: "Munic\u00EDpio", size: "small", error: !!fieldState.error, helperText: fieldState.error ? fieldState.error.message : " " }, field, { disabled: isEditMode
                                    ? (disabled || isAddressFromCep) && !enableAddressProofFields
                                    : disabled || isAddressFromCep, inputProps: { maxLength: 150 } })));
                        } })] })), _jsxs(Box, __assign({ sx: { display: "flex", flexDirection: "row", pt: 2 } }, { children: [_jsx(Button, __assign({ color: "primary", onClick: goToPreviousStep, sx: { mr: 1 } }, { children: "Voltar" })), _jsx(Box, { sx: { flex: "1 1 auto" } }), _jsx(Button, __assign({ variant: "contained", type: "submit" }, { children: "Pr\u00F3ximo" }))] }))] })));
};
export default AddressForm;
