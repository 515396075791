export var Situacao;
(function (Situacao) {
    Situacao["EmAnalise"] = "EM AN\u00C1LISE";
    Situacao["Deferido"] = "DEFERIDO";
    Situacao["Indeferido"] = "INDEFERIDO";
    Situacao["Pendente"] = "COM PEND\u00CANCIAS";
})(Situacao || (Situacao = {}));
export var Sexo;
(function (Sexo) {
    Sexo["Masculino"] = "M";
    Sexo["Feminino"] = "F";
    Sexo["Outro"] = "O";
})(Sexo || (Sexo = {}));
