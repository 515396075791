import { create } from "zustand";
export var useStepperStore = create(function (set, get) { return ({
    activeStep: 0,
    steps: ["Beneficiário", "Endereço", "Documentos"],
    isActiveStepValid: false,
    setActiveStep: function (activeStep) { return set({ activeStep: activeStep }); },
    isFirstStep: function () { return get().activeStep === 0; },
    isLastStep: function () { return get().activeStep === get().steps.length - 1; },
    setBeneficiaryData: function (data) { return set({ beneficiaryData: data }); },
    setAddressData: function (data) { return set({ addressData: data }); },
    setDocumentsData: function (data) { return set({ documentsData: data }); },
    goToNextStep: function () { return set(function (state) { return ({ activeStep: state.activeStep + 1 }); }); },
    goToPreviousStep: function () {
        return set(function (state) { return ({ activeStep: state.activeStep - 1 }); });
    },
    setIsActiveStepValid: function (isValid) { return set({ isActiveStepValid: isValid }); },
    resetAllData: function () {
        return set({
            beneficiaryData: undefined,
            addressData: undefined,
            documentsData: undefined,
        });
    },
}); });
