import { create } from "zustand";
export var useDocumentFieldsStore = create(function (set) { return ({
    enableIdentificationFields: false,
    enableMedicalReportFields: false,
    enableAddressProofFields: false,
    setTypeSegments: function (payload) {
        if (payload.includes("IDENTIFICACAO"))
            set({ enableIdentificationFields: true });
        if (payload.includes("LAUDO_MEDICO"))
            set({ enableMedicalReportFields: true });
        if (payload.includes("COMPROVANTE_DE_RESIDENCIA"))
            set({ enableAddressProofFields: true });
    },
}); });
