import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useWebgate } from "@xvia/webgate-connect";
import { useHistory } from "react-router-dom";
import { BackButton as BackButtonDS } from "pigov-design-system";
var BackButton = function () {
    var portalConnect = useWebgate().portalConnect;
    var goBack = useHistory().goBack;
    return (_jsx(BackButtonDS, { text: "Voltar", webView: portalConnect === null || portalConnect === void 0 ? void 0 : portalConnect.isRunningInWebview(), onClick: function () { return goBack(); } }));
};
export default BackButton;
