var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { create } from "zustand";
import { v4 as uuid } from "@lukeed/uuid";
export var useFilesStore = create(function (set, get) { return ({
    addFile: function (file) {
        var files = get().files;
        set({
            files: (files === null || files === void 0 ? void 0 : files.length)
                ? __spreadArray(__spreadArray([], files, true), [__assign({ id: uuid() }, file)], false) : [__assign({ id: uuid() }, file)],
        });
    },
    removeFile: function (id) {
        var _a;
        var files = (_a = get().files) === null || _a === void 0 ? void 0 : _a.filter(function (file) { return file.id !== id; });
        if ((files === null || files === void 0 ? void 0 : files.length) === 0) {
            set({ files: undefined });
        }
        else {
            set({ files: files });
        }
    },
    clearFiles: function () { return set({ files: undefined }); },
}); });
