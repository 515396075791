import { format, parse } from "date-fns";
export var prettifyDate = function (date) {
    return format(parse("".concat(date), "yyyyMMdd", new Date()), "dd/MM/yyyy");
};
export var formatDateToSend = function (date) {
    return Number(format(date, "yyyyMMdd"));
};
export var parseDateToRead = function (date) {
    return parse("".concat(date), "yyyyMMdd", new Date());
};
