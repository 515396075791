import * as z from "zod";
import { UFS, GENDERS } from "../util/constants";
import { zodStringOptionalOrEmpty } from "../util/validation";
var requiredFieldMessage = "Campo obrigatório";
var emptySelectRefinementCheck = function (value) { return value !== ""; };
var requiredFileValue = z.instanceof(File, { message: requiredFieldMessage });
var optionalFileValue = z.instanceof(File).optional().or(z.literal(null));
export var solicitationFormSchema = z.object({
    nome: z
        .string({ errorMap: function () { return ({ message: requiredFieldMessage }); } })
        .nonempty({ message: requiredFieldMessage }),
    cpf: z
        .string({ errorMap: function () { return ({ message: requiredFieldMessage }); } })
        .nonempty({ message: requiredFieldMessage }),
    rgOrgaoExpedidor: z
        .string({
        errorMap: function () { return ({ message: requiredFieldMessage }); },
    })
        .max(3, "No máximo 3 caracteres")
        .nonempty({ message: requiredFieldMessage }),
    rgUf: z
        .enum(UFS, { errorMap: function () { return ({ message: requiredFieldMessage }); } })
        .or(z.string())
        .refine(emptySelectRefinementCheck, { message: requiredFieldMessage }),
    rgDataExpedicao: z.instanceof(Date, { message: requiredFieldMessage }),
    rg: z
        .string({
        errorMap: function () { return ({ message: requiredFieldMessage }); },
    })
        .max(14, "No máximo 14 caracteres")
        .nonempty({ message: requiredFieldMessage }),
    dataNascimento: z.instanceof(Date, { message: requiredFieldMessage }),
    sexo: z
        .enum(GENDERS, { errorMap: function () { return ({ message: requiredFieldMessage }); } })
        .or(z.string())
        .refine(emptySelectRefinementCheck, { message: requiredFieldMessage }),
    naturalidadeUf: z
        .enum(UFS, { errorMap: function () { return ({ message: requiredFieldMessage }); } })
        .or(z.string())
        .refine(emptySelectRefinementCheck, { message: requiredFieldMessage }),
    naturalidade: z
        .string({
        errorMap: function () { return ({ message: requiredFieldMessage }); },
    })
        .nonempty({ message: requiredFieldMessage }),
    nacionalidade: z
        .string({
        errorMap: function () { return ({ message: requiredFieldMessage }); },
    })
        .nonempty({ message: requiredFieldMessage }),
    mae: z
        .string({ errorMap: function () { return ({ message: requiredFieldMessage }); } })
        .nonempty({ message: requiredFieldMessage }),
    pai: zodStringOptionalOrEmpty,
    email: z
        .string({ errorMap: function () { return ({ message: requiredFieldMessage }); } })
        .nonempty({ message: requiredFieldMessage })
        .email({ message: "Formato de e-mail inválido" }),
    fone1: z
        .string({ errorMap: function () { return ({ message: requiredFieldMessage }); } })
        .nonempty({ message: requiredFieldMessage }),
    cep: z
        .string({ errorMap: function () { return ({ message: requiredFieldMessage }); } })
        .nonempty({ message: requiredFieldMessage }),
    logradouro: z
        .string({ errorMap: function () { return ({ message: requiredFieldMessage }); } })
        .nonempty({ message: requiredFieldMessage }),
    numero: z
        .string({ errorMap: function () { return ({ message: requiredFieldMessage }); } })
        .nonempty({ message: requiredFieldMessage }),
    bairro: z
        .string({
        errorMap: function () { return ({ message: requiredFieldMessage }); },
    })
        .nonempty({ message: requiredFieldMessage }),
    complemento: zodStringOptionalOrEmpty,
    uf: z
        .enum(UFS, { errorMap: function () { return ({ message: requiredFieldMessage }); } })
        .or(z.string())
        .refine(emptySelectRefinementCheck, { message: requiredFieldMessage }),
    cidade: z
        .string({ errorMap: function () { return ({ message: requiredFieldMessage }); } })
        .nonempty({ message: requiredFieldMessage }),
    identificacao: requiredFileValue,
    foto: requiredFileValue,
    residencia: requiredFileValue,
    laudo: requiredFileValue,
    complementares: z.array(z.instanceof(File)).optional().or(z.literal(null)),
    termoDeResponsabilidade: z.literal(true, {
        errorMap: function () { return ({
            message: "Você deve aceitar os termos de responsabilidade",
        }); },
    }),
});
export var mapSolicitationPayloadToFormData = function (solicitationPayload) {
    var data = new FormData();
    if (solicitationPayload.solicitacaoId) {
        data.append("solicitacaoId", "".concat(solicitationPayload.solicitacaoId).toUpperCase());
    }
    var endereco = solicitationPayload.endereco, pessoa = solicitationPayload.pessoa;
    // Prepare user attributes
    data.append("pessoa.cpf", pessoa.cpf.toUpperCase());
    data.append("pessoa.nome", pessoa.nome.toUpperCase());
    data.append("pessoa.rg", pessoa.rg.toUpperCase());
    data.append("pessoa.rgUf", pessoa.rgUf.toUpperCase());
    data.append("pessoa.rgDataExpedicao", "".concat(pessoa.rgDataExpedicao).toUpperCase());
    data.append("pessoa.rgOrgaoExpedidor", pessoa.rgOrgaoExpedidor.toUpperCase());
    data.append("pessoa.sexo", pessoa.sexo.toUpperCase());
    data.append("pessoa.dataNascimento", "".concat(pessoa.dataNascimento).toUpperCase());
    data.append("pessoa.naturalidade", pessoa.naturalidade.toUpperCase());
    data.append("pessoa.nacionalidade", pessoa.nacionalidade.toUpperCase());
    data.append("pessoa.mae", pessoa.mae.toUpperCase());
    if (pessoa.pai) {
        data.append("pessoa.pai", pessoa.pai.toUpperCase());
    }
    // Address attributes
    data.append("endereco.cep", endereco.cep.toUpperCase());
    data.append("endereco.logradouro", endereco.logradouro.toUpperCase());
    data.append("endereco.numero", "".concat(endereco.numero).toUpperCase());
    data.append("endereco.bairro", endereco.bairro.toUpperCase());
    data.append("endereco.complemento", endereco.complemento.toUpperCase());
    data.append("endereco.uf", endereco.uf.toUpperCase());
    data.append("endereco.cidade", endereco.cidade.toUpperCase());
    data.append("endereco.email", endereco.email.toLowerCase());
    data.append("endereco.fone1", endereco.fone1.toUpperCase());
    // Files attributes
    data.append("identificacao", solicitationPayload.identificacao);
    data.append("residencia", solicitationPayload.residencia);
    data.append("foto", solicitationPayload.foto);
    data.append("laudo", solicitationPayload.laudo);
    if (solicitationPayload.complementares) {
        solicitationPayload.complementares.forEach(function (complementar) {
            if (complementar)
                data.append("complementar", complementar);
        });
    }
    // Meta attributes
    data.append("termoDeResponsabilidade", "".concat(solicitationPayload.termoDeResponsabilidade));
    return data;
};
export var generateSolicitationFormSchema = function (schema, documentObservationMap) {
    var _a, _b, _c, _d;
    if (!documentObservationMap)
        return schema;
    return schema
        .setKey("identificacao", ((_a = documentObservationMap["Identificação"]) === null || _a === void 0 ? void 0 : _a.observacao)
        ? requiredFileValue
        : optionalFileValue)
        .setKey("foto", ((_b = documentObservationMap["Foto"]) === null || _b === void 0 ? void 0 : _b.observacao)
        ? requiredFileValue
        : optionalFileValue)
        .setKey("residencia", ((_c = documentObservationMap["Comprovante de Residência"]) === null || _c === void 0 ? void 0 : _c.observacao)
        ? requiredFileValue
        : optionalFileValue)
        .setKey("laudo", ((_d = documentObservationMap["Laudo Médico"]) === null || _d === void 0 ? void 0 : _d.observacao)
        ? requiredFileValue
        : optionalFileValue);
};
