import * as z from "zod";
import { requiredFileValue } from "../util/validation";
export var documentsFormSchema = z.object({
    arquivos: z
        .array(z.object({
        tipo: z.string(),
        descricao: z.string().optional(),
        file: requiredFileValue,
    }))
        .min(1),
    termoDeResponsabilidade: z.literal(true, {
        errorMap: function () { return ({
            message: "Você deve aceitar os termos de responsabilidade",
        }); },
    }),
});
export var resendDocumentsFormSchema = z.object({
    arquivos: z
        .array(z.object({
        tipo: z.string(),
        descricao: z.string().optional(),
        file: requiredFileValue,
    }))
        .min(1),
});
