var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useRef } from "react";
import { MuiFileInput } from "mui-file-input";
import { IconButton, InputAdornment } from "@mui/material";
import { UploadFileRounded } from "@mui/icons-material";
var RawFileInput = function (_a) {
    var downloadFilePath = _a.downloadFilePath, token = _a.token, documentId = _a.documentId, fileInputProps = __rest(_a, ["downloadFilePath", "token", "documentId"]);
    var fileInputRef = useRef(null);
    var handleUploadIconClick = function () { var _a; return (_a = fileInputRef.current) === null || _a === void 0 ? void 0 : _a.click(); };
    return (_jsx(MuiFileInput, __assign({}, fileInputProps, { inputRef: fileInputRef, InputProps: {
            startAdornment: (_jsx(InputAdornment, __assign({ position: "start", color: fileInputProps.error ? "error" : "primary" }, { children: _jsx(IconButton, __assign({ color: fileInputProps.error ? "error" : "primary", sx: { p: 0 }, onClick: handleUploadIconClick, disabled: fileInputProps.disabled }, { children: _jsx(UploadFileRounded, {}) })) }))),
        }, sx: {
            "& label span": {
                width: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            },
        } })));
};
export default RawFileInput;
