import * as z from "zod";
import { UFS } from "../util/constants";
import { emptySelectRefinementCheck, requiredFieldMessage, zodStringOptionalOrEmpty, } from "../util/validation";
export var addressFormSchema = z.object({
    cep: z
        .string()
        .min(1, requiredFieldMessage)
        .refine(function (value) {
        var cepValueField = value.split(" ").join("").replace("-", "");
        return cepValueField.length === 8;
    }, { message: "O campo CEP deve conter no mínimo 9 caracteres" }),
    logradouro: z.string().min(1, requiredFieldMessage),
    numero: z.string().min(1, requiredFieldMessage),
    bairro: z.string().min(1, requiredFieldMessage),
    complemento: zodStringOptionalOrEmpty,
    uf: z
        .enum(UFS, { errorMap: function () { return ({ message: requiredFieldMessage }); } })
        .or(z.string())
        .refine(emptySelectRefinementCheck, { message: requiredFieldMessage }),
    cidade: z.string().min(1, requiredFieldMessage),
});
