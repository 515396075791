var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from "react";
import { Step, StepLabel, Stepper, Typography } from "@mui/material";
import BeneficiaryForm from "./BeneficiaryForm";
import AddressForm from "./AddressForm";
import DocumentsForm from "./DocumentsForm";
import { useStepperStore } from "../../stores/stepper";
var SolicitationStepper = function (_a) {
    var id = _a.id, _b = _a.mode, mode = _b === void 0 ? "create" : _b, defaultValues = _a.defaultValues, disableSubmit = _a.disableSubmit, documentObservationMap = _a.documentObservationMap, token = _a.token, onFormSubmit = _a.onFormSubmit, documents = _a.documents;
    var activeStep = useStepperStore(function (state) { return state.activeStep; });
    var steps = useStepperStore(function (state) { return state.steps; });
    var isActiveStepValid = useStepperStore(function (state) { return state.isActiveStepValid; });
    var isEditMode = mode === "edit";
    var isRenewMode = mode === "renew";
    var solicitationFormStepsMap = useMemo(function () {
        return new Map([
            [
                0,
                function () { return (_jsx(BeneficiaryForm, { mode: mode, disabled: isEditMode || isRenewMode, defaultValues: defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.beneficiary, token: token })); },
            ],
            [
                1,
                function () { return (_jsx(AddressForm, { mode: mode, disabled: isEditMode, defaultValues: defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.address })); },
            ],
            [
                2,
                function () { return (_jsx(DocumentsForm, { id: id, mode: mode, defaultValues: defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.documents, disableSubmit: disableSubmit, documentObservationMap: documentObservationMap, onFormSubmit: onFormSubmit, token: token, documents: mode === "edit" ? documents : [] })); },
            ],
        ]);
    }, [
        isEditMode,
        isRenewMode,
        defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.beneficiary,
        defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.address,
        defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.documents,
        token,
        id,
        mode,
        disableSubmit,
        documentObservationMap,
        onFormSubmit,
        documents,
    ]);
    var FormStep = solicitationFormStepsMap.get(activeStep);
    return (_jsxs(_Fragment, { children: [_jsx(Stepper, __assign({ activeStep: activeStep, sx: { my: 5 } }, { children: steps.map(function (label, index) {
                    var stepProps = {};
                    var labelProps = {};
                    if (isActiveStepValid && activeStep === index) {
                        labelProps.optional = (_jsx(Typography, __assign({ variant: "caption", color: "error" }, { children: "Formul\u00E1rio inv\u00E1lido" })));
                        labelProps.error = true;
                    }
                    return (_jsx(Step, __assign({}, stepProps, { children: _jsx(StepLabel, __assign({}, labelProps, { sx: function (theme) {
                                var _a;
                                return (_a = {
                                        display: "flex"
                                    },
                                    _a[theme.breakpoints.down(420)] = {
                                        flexDirection: "column",
                                    },
                                    _a);
                            } }, { children: label })) }), label));
                }) })), _jsx(FormStep, {})] }));
};
export default SolicitationStepper;
