var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Stack, TextField, } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { fileFormSchema } from "../../forms/file";
import { zodResolver } from "@hookform/resolvers/zod";
import { useFilesStore } from "../../stores/files";
import PictureInfoDialog from "../PictureInfoDialog";
import RawFileInput from "../RawFileInput";
import { isAxiosError } from "axios";
import { getTiposAnexos } from "../../services/api/seid";
import { useLoading } from "../Loading";
var AddFileDialog = function (_a) {
    var token = _a.token, open = _a.open, onClose = _a.onClose, solicitationDocumentTypes = _a.solicitationDocumentTypes;
    var loading = useLoading();
    var _b = useState(null), selectedTipoAnexo = _b[0], setSelectedTipoAnexo = _b[1];
    var _c = useState(false), openPictureInfoDialog = _c[0], setOpenPictureInfoDialog = _c[1];
    var _d = useState([]), tiposAnexos = _d[0], setTiposAnexos = _d[1];
    var addFile = useFilesStore(function (state) { return state.addFile; });
    var selectedFilesTypes = useFilesStore(function (state) { var _a; return (_a = state.files) === null || _a === void 0 ? void 0 : _a.map(function (file) { return file.type; }); });
    var form = useForm({
        defaultValues: { type: "", file: undefined },
        resolver: zodResolver(fileFormSchema),
    });
    var typeValue = form.watch("type");
    var handleOnClose = function () {
        onClose();
        form.reset();
    };
    var onSubmit = function (data) {
        addFile({
            type: data.type,
            file: data.file,
        });
        handleOnClose();
    };
    useEffect(function () {
        var getTiposAnexosData = function () { return __awaiter(void 0, void 0, void 0, function () {
            var tiposAnexosResult;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!token) return [3 /*break*/, 2];
                        loading.show();
                        return [4 /*yield*/, getTiposAnexos()];
                    case 1:
                        tiposAnexosResult = _a.sent();
                        loading.hide();
                        if (!isAxiosError(tiposAnexosResult))
                            setTiposAnexos(tiposAnexosResult.list);
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        getTiposAnexosData();
    }, [loading, token]);
    useEffect(function () {
        var currentTipoAnexo = tiposAnexos === null || tiposAnexos === void 0 ? void 0 : tiposAnexos.find(function (tipoAnexo) { return tipoAnexo.nome === typeValue; });
        setSelectedTipoAnexo(currentTipoAnexo);
        if ((currentTipoAnexo === null || currentTipoAnexo === void 0 ? void 0 : currentTipoAnexo.nome) === "FOTO") {
            setOpenPictureInfoDialog(true);
        }
    }, [tiposAnexos, typeValue]);
    return (_jsxs(_Fragment, { children: [_jsx(Dialog, __assign({ open: open, onClose: onClose }, { children: _jsxs("form", { children: [_jsx(DialogTitle, { children: "Adicionar arquivo" }), _jsxs(DialogContent, { children: [_jsx(DialogContentText, { children: "Preencha os campos abaixo para adicionar um novo arquivo." }), _jsxs(Stack, __assign({ spacing: 2, sx: { mt: 2 } }, { children: [_jsx(Controller, { control: form.control, name: "type", render: function (_a) {
                                                var field = _a.field, fieldState = _a.fieldState;
                                                return (_jsx(TextField, __assign({ select: (tiposAnexos === null || tiposAnexos === void 0 ? void 0 : tiposAnexos.length) !== 0, fullWidth: true, variant: "outlined", label: "Tipo do documento", size: "small", error: !!fieldState.error, helperText: fieldState.error ? fieldState.error.message : " ", disabled: (tiposAnexos === null || tiposAnexos === void 0 ? void 0 : tiposAnexos.length) === 0, sx: { "& input": { textTransform: "uppercase" } } }, field, { children: tiposAnexos === null || tiposAnexos === void 0 ? void 0 : tiposAnexos.filter(function (documentType) {
                                                        var hasNoSelectedFilesTypes = !(selectedFilesTypes === null || selectedFilesTypes === void 0 ? void 0 : selectedFilesTypes.includes(documentType.nome));
                                                        if (solicitationDocumentTypes) {
                                                            return (solicitationDocumentTypes.includes(documentType.nome) && hasNoSelectedFilesTypes);
                                                        }
                                                        return hasNoSelectedFilesTypes;
                                                    }).map(function (documentType) { return (_jsx(MenuItem, __assign({ value: "".concat(documentType.nome) }, { children: documentType.descricao }), documentType.id)); }) })));
                                            } }), _jsx(Controller, { control: form.control, name: "file", render: function (_a) {
                                                var _b;
                                                var field = _a.field, fieldState = _a.fieldState;
                                                return (_jsx(RawFileInput, { fullWidth: true, variant: "outlined", label: "Documento", placeholder: "Clique para anexar", size: "small", getInputText: function (value) { return value.name; }, error: !!fieldState.error, helperText: fieldState.error ? fieldState.error.message : " ", value: (_b = field.value) !== null && _b !== void 0 ? _b : null, onChange: function (value) {
                                                        field.onChange(value);
                                                    }, onBlur: field.onBlur, inputProps: (selectedTipoAnexo === null || selectedTipoAnexo === void 0 ? void 0 : selectedTipoAnexo.nome) === "FOTO"
                                                        ? { accept: "image/png, image/jpeg" }
                                                        : { accept: "image/png, image/jpeg, application/pdf" } }));
                                            } })] }))] }), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ variant: "outlined", onClick: handleOnClose }, { children: "Fechar" })), _jsx(Button, __assign({ type: "button", variant: "contained", onClick: form.handleSubmit(onSubmit) }, { children: "Adicionar" }))] })] }) })), _jsx(PictureInfoDialog, { open: openPictureInfoDialog, onClose: function () { return setOpenPictureInfoDialog(false); } })] }));
};
export default AddFileDialog;
